import types from '../types'
import Helpers from '../Helpers'

export const getLogs =
  (orgId, limit = 50) =>
    async (dispatch, getState) => {
      const userReducer = getState().userReducer
      const oldLogs = userReducer.logs || []
      const authKey = userReducer.authKey
      const path = process.env.REACT_APP_METRICS_URL + `api/query/basic-logs?limit=${limit + oldLogs.length}`
      const headers = {
        'orgId': orgId,
        'authKey': authKey,
      }
      Helpers.fetchGet(path, headers)
          .then((res) => {
            const logsObj = res?.logs || []
            dispatch({
              type: types.STORE_PAYLOAD,
              payload: {
                logs: logsObj.map((log) => {
                  return {
                    id: log.request_id,
                    ...log,
                  }
                }),
              },
            })
          })
          .catch((err) => {
            console.log('Error: ', err)
          })
    }
