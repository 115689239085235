import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'

import {signOut} from '../actions/signOut'
import {markUserTutorialized} from '../actions/markUserTutorialized.js'
import {storePayload} from '../actions/storePayload'

import {Modal2, ToolTip, Button} from '@lazarusai/forms-ui-components'
import {Demo} from '@lazarusai/forms-demo'

import '../styles/DemoWrapper.css'

function DemoWrapper(props) {
  const [isToolTip1Showing, setIsToolTip1Showing] = useState(true)
  const [isToolTip2Showing, setIsToolTip2Showing] = useState(false)
  const [toolTipStep, setToolTipStep] = useState(1)
  const [isLowPromoModalShowing, setIsLowPromoModalShowing] = useState(false)

  const canAccessToolTipPages =
    props.usersObj?.[props.user.uid]?.permissions?.viewCredentials ||
    props.usersObj?.[props.user.uid]?.role === 'admin'

  function onDismissToolTip() {
    if (!toolTipStep) {
      setIsToolTip1Showing(true)
      setToolTipStep(1)
    } else if (toolTipStep === 1) {
      setIsToolTip1Showing(false)
      setIsToolTip2Showing(true)
      setToolTipStep(2)
    } else {
      setIsToolTip2Showing(false)
      setToolTipStep(3)
      props.markUserTutorialized(props.database, props.user)
    }
  }

  function getLinkLocation(link_id) {
    const el = document.getElementById(link_id)
    const elBoundingBox = el.getBoundingClientRect()
    // const centerY = (elBoundingBox.top + elBoundingBox.bottom) / 2
    return elBoundingBox.top
  }

  useEffect(() => {
    if (props.billing.promo.amount < 1 && !props.billing?.contract?.Active && !props.hasGivenBillingWarning) {
      props.storePayload({hasGivenBillingWarning: true})
      setIsLowPromoModalShowing(true)
    }
  }, [props.billing])

  useEffect(() => {
    const mainEl = document.getElementById('main')
    mainEl.addEventListener('click', () => {
      const imageEl = document.getElementById('image-container')
      if (imageEl && !props.isUsingDemo) {
        props.storePayload({
          isUsingDemo: true,
        })
      }
    })
  }, [])

  return (
    <div id='demo-wrapper'>
      <Modal2
        title='Few Promo Credits Remaining'
        showClose={false}
        isVisible={isLowPromoModalShowing}
        theme={props.theme}
        footer={<div className='promo-footer'>
          <Link to='/'>
            <Button
              type={10}
              theme={props.theme}
              text={'Cancel'}
            />
          </Link>
          <Button
            type={8}
            theme={props.theme}
            text={'Continue'}
            onClick={() => {
              setIsLowPromoModalShowing(false)
            }}
          />
        </div>}
      >
        <p>You currently have ${props.billing.promo.amount} promo credits.</p>
        <p>Your requests will fail if you don&apos;t have sufficient promo credits.</p>
        <p>Would you like to continue?</p>
      </Modal2>
      {props.isFirstTimeUser && canAccessToolTipPages && (
        <>
          <div
            id='tooltip'
            style={{
              position: 'absolute',
              left: 0,
              top: `calc(${getLinkLocation('api')}px - .5em)`,
              zIndex: 13,
            }}
          >
            <ToolTip
              text='You can find your API key and Organization Id in the API tab'
              onDismiss={onDismissToolTip}
              arrowPosition={7}
              isVisible={isToolTip1Showing}
            />
          </div>
          <div
            id='tooltip'
            style={{
              position: 'absolute',
              left: 0,
              top: `calc(${getLinkLocation('quickstart')}px - .5em)`,
              zIndex: 12,
            }}
          >
            <ToolTip
              text='Visit the quickstart page to get started yourself'
              onDismiss={onDismissToolTip}
              arrowPosition={7}
              isVisible={isToolTip2Showing}
            />
          </div>
          {toolTipStep < 3 && (
            <>
              <div id='nav-cover' />
              <div className='backdrop' />
            </>
          )}
        </>
      )}
      {!isLowPromoModalShowing && <Demo
        database={props.database}
        firestore={props.firestore}
        orgId={props.orgId}
        authKey={props.authKey}
        promoAmount={props.billing.promo.amount}
        showPromoAmount={!props.billing?.contract?.Active}
      />}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  isLoading: state.userReducer.isLoading,
  userId: state.userReducer.userId,
  theme: state.userReducer.theme,
  authKey: state.userReducer.authKey,
  orgId: state.userReducer.orgId,
  isFirstTimeUser: state.userReducer.isFirstTimeUser,
  usersObj: state.userReducer.usersObj,
  user: state.userReducer.user,
  billing: state.userReducer.billing,
  hasGivenBillingWarning: state.userReducer.hasGivenBillingWarning,
  isUsingDemo: state.userReducer.isUsingDemo,
})

export default connect(
    mapStateToProps,
    {signOut, markUserTutorialized, storePayload},
)(DemoWrapper)
