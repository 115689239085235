import React, {useState, useEffect, Fragment} from 'react'
import {connect} from 'react-redux'
import {Navigate, Link} from 'react-router-dom'

import {Table, TableEntry, Button, Modal2, Input, Select, Switch, Icon} from '@lazarusai/forms-ui-components'

// import Helpers from '../Helpers'
import {getUsers} from '../actions/getUsers'
import {storePayload} from '../actions/storePayload'
import {createSubOrganization} from '../actions/createSubOrganization'
// import endpoints from '../endpoints.json'
import '../styles/SubOrgDashboard.css'
import {getSubOrgBillingHistory} from '../actions/getSubOrgBillingHistory'
import {getSubOrganizationMetrics} from '../actions/getSubOrganizationMetrics'

import blockedEmails from '../blockedEmails.json'

function SubOrgDashboard(props) {
  const [isCreateModalShowing, setIsCreateModalShowing] = useState(false)
  const [subOrgEntries, setSubOrgEntries] = useState(null)
  const [newOrgName, setNewOrgName] = useState('')
  const [newOrgEmail, setNewOrgEmail] = useState('')
  const [newOrgParentOrg, setNewOrgParentOrg] = useState('')
  const [newOrgModelAccess, setNewOrgModelAccess] = useState([])
  const [isNewOrgCreditLimitOn, setIsNewOrgCreditLimitOn] = useState(false)
  const [newOrgCreditLimit, setNewOrgCreditLimit] = useState(0)
  const [newOrgCreditPeriod, setNewOrgCreditPeriod] = useState(null)
  const [isNewOrgPageLimitOn, setIsNewOrgPageLimitOn] = useState(false)
  const [newOrgPageLimit, setNewOrgPageLimit] = useState(0)
  const [newOrgPagePeriod, setNewOrgPagePeriod] = useState(null)
  const [newOrgModelPricing, setNewOrgModelPricing] = useState({})

  const hasSubOrgAccess = () => {
    return (props.usersObj[props.user.uid]['role'] === 'admin' || props.usersObj[props.user.uid]['permissions']['controlSubOrgs'])
  }

  function resetCreateForm() {
    setNewOrgName('')
    setNewOrgEmail('')
    setNewOrgParentOrg('')
    setNewOrgModelAccess([])
    setIsNewOrgCreditLimitOn(false)
    setNewOrgCreditLimit(0)
    setNewOrgCreditPeriod(null)
    setIsNewOrgPageLimitOn(false)
    setNewOrgPageLimit(0)
    setNewOrgPagePeriod(null)
    setNewOrgModelPricing({})
  }

  function getBillingHistory() {
    props.storePayload({
      userMessage: 'Getting billing history...',
      notificationType: 2,
      notificationIcon: 'check',
    })
    props.getSubOrgBillingHistory(props.orgId, props.authKey, props.user.uid)
  }

  function getCreateBodyFromState() {
    const body = {}
    body['orgName'] = newOrgName
    if (newOrgEmail.length) {
      body['orgEmail'] = newOrgEmail
    }
    body['parentOrgId'] = newOrgParentOrg
    body['modelIds'] = newOrgModelAccess
    body['billing'] = {}
    if (isNewOrgCreditLimitOn) {
      body['billing']['creditLimit'] = {
        'creditLimit': parseFloat(newOrgCreditLimit),
        'period': newOrgCreditPeriod,
      }
    }
    if (isNewOrgPageLimitOn) {
      body['billing']['pageLimit'] = {
        'pageLimit': parseFloat(newOrgPageLimit),
        'period': newOrgPagePeriod,
      }
    }
    if (newOrgModelAccess.length) {
      const modelPricesList = newOrgModelAccess
          .filter((modelId) => newOrgModelPricing?.[modelId]?.['isSetting'])
          .map((modelId) => {
            const interObj = {}
            interObj['perPage'] = parseFloat(newOrgModelPricing[modelId]['perPage']) || null
            interObj['perQuestion'] = parseFloat(newOrgModelPricing[modelId]['perQuestion']) || null
            interObj['perRequest'] = parseFloat(newOrgModelPricing[modelId]['perRequest']) || null
            const returnObj = {}
            returnObj[modelId] = interObj
            return returnObj
          })
      const modelPrices = Object.assign({}, ...modelPricesList)
      body['billing']['pricing'] = modelPrices
    }
    return body
  }

  async function onClickCreate() {
    setIsCreateModalShowing(false)
    const successCreate = await props.createSubOrganization(props.orgId, props.authKey, props.user.uid, getCreateBodyFromState())
    if (successCreate) {
      resetCreateForm()
    }
    props.getSubOrganizationMetrics(props.database, props.orgId, props.user.uid)
  }

  useEffect(() => {
    props.storePayload({
      subOrganizationDetails: null,
    })
  }, [])

  useEffect(() => {
    if (props.subOrganizationMetrics) {
      const newEntries = []
      const subOrgIds = Object.keys(props.subOrganizationMetrics)
      for (let sIndex = 0; sIndex < subOrgIds.length; sIndex++) {
        const subOrgId = subOrgIds[sIndex]
        newEntries.push(<TableEntry
          key={`sub-org-row-${subOrgId}`}
          content={[
            <Link
              key={`sub-org-row-link-${subOrgId}`}
              to={`/suborganizations/${subOrgId}`}
            >
              {props.subOrganizationMetrics[subOrgId].name}
            </Link>,
            subOrgId,
            props.subOrganizationMetrics[subOrgId].totalPages,
            props.subOrganizationMetrics[subOrgId].totalRequests,
            props.subOrganizationMetrics[subOrgId].totalQuestions,
          ]}
          rowId={`sub-org-row-${subOrgId}`}
        />)
      }
      setSubOrgEntries(newEntries)
    }
  }, [props.subOrganizationMetrics])


  const isBusinessEmail = (email) => {
    return !blockedEmails.blockedEmails.some((emailProvider) => email.includes(emailProvider))
  }

  if (!hasSubOrgAccess()) {
    return <Navigate to='/' />
  }
  return (
    <div className='sub-org-dashboard'>
      <Modal2
        title='Create Sub Organization'
        onDismiss={() => {
          setIsCreateModalShowing(false)
          resetCreateForm()
        }}
        isVisible={isCreateModalShowing}
        theme={props.theme}
        outsideClickDismisses={true}
        hasLine={true}
        style={{height: '80vh'}}
        contentContainerStyle={{scrollbarWidth: 'initial'}}
        contentStyle={{
          height: '100%',
          width: '100%',
        }}
        footer={
          <Button
            text={'Create Sub Organization'}
            styles={{margin: '0em'}}
            theme={props.theme}
            type={8}
            disabled={!(newOrgName.length && newOrgParentOrg.length && isBusinessEmail(newOrgEmail))}
            onClick={onClickCreate}
          />
        }
      >
        <div
          className='new-sub-org-modal-content-wrapper show-scrollbar'
        >
          {/* Org Name */}
          <Input
            label='Organization Name (Required)'
            placeholder='Enter the name...'
            theme={props.theme}
            type={1}
            value={newOrgName}
            onChange={(e) => {
              setNewOrgName(e.target.value)
            }}
            style={{marginBottom: '0px'}}
          />
          {/* Org Email */}
          <Input
            label='Organization Email'
            placeholder='Enter the email...'
            info='The email for the first account made for this organization'
            theme={props.theme}
            type={1}
            value={newOrgEmail}
            onChange={(e) => {
              if (!isBusinessEmail(e.target.value)) {
                props.storePayload({
                  userMessage: 'Business emails are required.',
                  notificationType: 2,
                  notificationIcon: 'warning',
                })
              }
              setNewOrgEmail(e.target.value)
            }}
            style={{marginBottom: '0px'}}
          />
          {/* Parent Org */}
          <Select
            value={newOrgParentOrg ?
            `${newOrgParentOrg} - ${props.subOrganizationMetrics[newOrgParentOrg]?.name || 'Your Organization'}` :
            newOrgParentOrg}
            label='Parent Organization (Required)'
            theme={props.theme}
            isSelectedYellow={true}
            options={[
              `${props.orgId} - Your Organization`,
              ...Object.keys(props.subOrganizationMetrics || {})
                  .map((subOrgId) => `${subOrgId} - ${props.subOrganizationMetrics[subOrgId]?.name}`),
            ]}
            onClickOption={(option) => setNewOrgParentOrg(option.split(' - ')[0])}
          />
          {/* Model Ids */}
          <Select
            value={newOrgModelAccess.map((modelId) => `${modelId} - ${props.modelInformation[modelId]?.public?.metadata?.name}`)}
            label='Model Access'
            theme={props.theme}
            isSelectedYellow={true}
            options={props.modelAccess ? props.modelAccess.
                filter((modelId) => props.modelInformation[modelId]?.public && !['genericFormApi2', 'invoices', 'receipts'].includes(modelId)).
                map((modelId) => `${modelId} - ${props.modelInformation[modelId]?.public?.metadata?.name}`) :
              []}
            isMultiselect={true}
            onClickOption={(option) => {
              if (newOrgModelAccess.includes(option.split(' - ')[0])) {
                setNewOrgModelAccess(JSON.parse(JSON.stringify(newOrgModelAccess.filter((val) => val !== option.split(' - ')[0]))))
              } else {
                setNewOrgModelAccess([...newOrgModelAccess, option.split(' - ')[0]])
              }
            }}
          />
          {/* Credit Limit */}
          <Switch
            label='Enable Credit Limit'
            checked={isNewOrgCreditLimitOn}
            onChange={(e) => setIsNewOrgCreditLimitOn(e.target.checked)}
            theme={props.theme}
            id='credit-switch'
            key={`credit-switch`}
          />
          {isNewOrgCreditLimitOn &&
          <>
            <Input
              label='Organization Credit Limit ($ / period)'
              placeholder='Enter the credit limit amount...'
              theme={props.theme}
              type={1}
              value={newOrgCreditLimit}
              onChange={(e) => {
                setNewOrgCreditLimit(e.target.value)
              }}
              className={(!newOrgCreditLimit || parseFloat(newOrgCreditLimit) > 0) ? '': 'input-error'}
              style={{marginBottom: '0px'}}
            />
            <Select
              value={newOrgCreditPeriod}
              label='Organization Credit Limit Period (period in which the credit limit is assigned)'
              theme={props.theme}
              isSelectedYellow={true}
              options={['month', 'day', 'year', 'future']}
              onClickOption={(option) => {
                setNewOrgCreditPeriod(option)
              }}
            />
          </>
          }
          {/* Page Limit */}
          <Switch
            label='Enable Page Limit'
            checked={isNewOrgPageLimitOn}
            onChange={(e) => setIsNewOrgPageLimitOn(e.target.checked)}
            theme={props.theme}
            id='page-switch'
            key={`page-switch`}
          />
          {isNewOrgPageLimitOn &&
          <>
            <Input
              label='Organization Page Limit (# pages / period)'
              placeholder='Enter the Page limit amount...'
              theme={props.theme}
              type={1}
              value={newOrgPageLimit}
              onChange={(e) => {
                setNewOrgPageLimit(e.target.value)
              }}
              className={(!newOrgPageLimit || parseFloat(newOrgPageLimit) > 0) ? '': 'input-error'}
              style={{marginBottom: '0px'}}
            />
            <Select
              value={newOrgPagePeriod}
              label='Organization Page Limit Period (period in which the page limit is assigned)'
              theme={props.theme}
              isSelectedYellow={true}
              options={['month', 'day', 'year', 'future']}
              onClickOption={(option) => {
                setNewOrgPagePeriod(option)
              }}
            />
          </>
          }
          {/* Pricing */}
          {newOrgModelAccess.map((modelId) => {
            return (
              <Fragment key={`${modelId}-price`}>
                <Switch
                  key={`${modelId}-switch`}
                  label={`Set ${props.modelInformation[modelId]?.public?.metadata?.name} Pricing`}
                  checked={newOrgModelPricing?.[modelId]?.isSetting}
                  onChange={(e) => {
                    if (!newOrgModelPricing?.[modelId]) {
                      newOrgModelPricing[modelId] = {isSetting: true}
                    } else if (!newOrgModelPricing?.[modelId]?.isSetting) {
                      newOrgModelPricing[modelId].isSetting = true
                    } else {
                      newOrgModelPricing[modelId].isSetting = false
                    }
                    setNewOrgModelPricing(JSON.parse(JSON.stringify(newOrgModelPricing)))
                  }}
                  theme={props.theme}
                  id={`${modelId}-switch`}
                />
                {newOrgModelPricing?.[modelId]?.isSetting &&
                <>
                  <Input
                    label='Price Per Page'
                    placeholder='Enter the price per page...'
                    theme={props.theme}
                    type={1}
                    value={`${newOrgModelPricing?.[modelId]?.perPage || ''}`}
                    onChange={(e) => {
                      newOrgModelPricing[modelId].perPage = e.target.value
                      setNewOrgModelPricing(JSON.parse(JSON.stringify(newOrgModelPricing)))
                    }}
                    className={(!newOrgModelPricing[modelId].perPage || parseFloat(newOrgModelPricing[modelId].perPage) > 0) ? '': 'input-error'}
                    style={{marginBottom: '0px', boxSizing: 'border-box', paddingLeft: '16px'}}
                    key={`${modelId}-price-page`}
                  />
                  <Input
                    label='Price Per Question'
                    placeholder='Enter the price per question...'
                    theme={props.theme}
                    type={1}
                    value={`${newOrgModelPricing?.[modelId]?.perQuestion || ''}`}
                    onChange={(e) => {
                      newOrgModelPricing[modelId].perQuestion = e.target.value
                      setNewOrgModelPricing(JSON.parse(JSON.stringify(newOrgModelPricing)))
                    }}
                    className={(!newOrgModelPricing[modelId].perQuestion || parseFloat(newOrgModelPricing[modelId].perQuestion) > 0) ? '': 'input-error'}
                    style={{marginBottom: '0px', boxSizing: 'border-box', paddingLeft: '16px'}}
                    key={`${modelId}-price-question`}
                  />
                  <Input
                    label='Price Per Request'
                    placeholder='Enter the price per request...'
                    theme={props.theme}
                    type={1}
                    value={`${newOrgModelPricing?.[modelId]?.perRequest || ''}`}
                    onChange={(e) => {
                      newOrgModelPricing[modelId].perRequest = e.target.value
                      setNewOrgModelPricing(JSON.parse(JSON.stringify(newOrgModelPricing)))
                    }}
                    className={(!newOrgModelPricing[modelId].perRequest || parseFloat(newOrgModelPricing[modelId].perRequest) > 0) ? '': 'input-error'}
                    style={{marginBottom: '0px', boxSizing: 'border-box', paddingLeft: '16px'}}
                    key={`${modelId}-price-request`}
                  />
                </>
                }
              </Fragment>
            )
          })}
        </div>
      </Modal2>
      <div
        className='sub-org-body'
      >
        <Table
          title='Sub Organizations'
          showTotal={(subOrgEntries && subOrgEntries.length) ? true : false}
          columnTitles={['Name', 'ID', 'Total Pages', 'Total Requests', 'Total Questions']}
          tableId={'sub-orgs-table'}
          entries={subOrgEntries === null ?
            [<TableEntry
              key={'loading-row'}
              content={['Sub Organizations Loading']}
            />] :
            subOrgEntries.length ?
              subOrgEntries :
              [<TableEntry
                key={'no-sub-row'}
                content={['No Sub Organizations']}
              />]
          }
          className='sub-org-table'
          theme={props.theme}
        />
      </div>
      <div
        className='sub-org-footer'
      >
        <Button
          text={'Add Sub Org'}
          styles={{margin: '0em'}}
          theme={props.theme}
          type={4}
          disabled={!props.orgDetails?.permissions?.canCreateSubOrg}
          onClick={() => setIsCreateModalShowing(true)}
        />
        <Button
          theme={props.theme}
          text='Get Billing History'
          icon={<Icon icon='download-outline' />}
          iconPosition='right'
          iconJustify='edge'
          download={true}
          onClick={getBillingHistory}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  database: state.firebaseReducer.database,
  usersObj: state.userReducer.usersObj,
  users: state.userReducer.users,
  userData: state.userReducer.userData,
  user: state.userReducer.user,
  orgDetails: state.userReducer.orgDetails,
  theme: state.userReducer.theme,
  orgId: state.userReducer.orgId,
  authKey: state.userReducer.authKey,
  subOrganizationMetrics: state.userReducer.subOrganizationMetrics,
  modelAccess: state.userReducer.modelAccess,
  modelInformation: state.userReducer.modelInformation,
})

export default connect(mapStateToProps, {
  getUsers,
  storePayload,
  createSubOrganization,
  getSubOrgBillingHistory,
  getSubOrganizationMetrics,
  // getBilling,
})(SubOrgDashboard)
