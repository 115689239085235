import React, {useState, useEffect, useRef} from 'react'
import {connect} from 'react-redux'
import {Link, Navigate} from 'react-router-dom'

import {TabContainer, Table, TableEntry,
  Button, Icon, Card, Input,
  SearchBar} from '@lazarusai/forms-ui-components'

import PermissionSideTab from './PermissionSideTab'
import Helpers from '../Helpers'
import {getUsers} from '../actions/getUsers'
import {storePayload} from '../actions/storePayload'
import endpoints from '../endpoints.json'
import '../styles/VKGDashboard.css'
import '../styles/InputMulti.css'
import AddUsersSideTab from './AddUsersSideTab'

function VKGDashboard(props) {
  const [activeTab, setActiveTab] = useState('graphs')
  const [canAccessPermissions, setCanAccessPermissions] = useState(false)
  const [entries, setEntries] = useState([])
  const [permissionsModalShowing, setPermissionsModalShowing] = useState(false)
  const [permissionsID, _setPermissionsID] = useState(null)
  const permissionsIDRef = useRef(null)
  const [permissionsEmail, setPermissionsEmail] = useState(null)
  const [permissionsUser, setPermissionsUser] = useState(null)
  const [graphEntries, setGraphEntries] = useState([])
  const [isManageGraphView, setIsManageGraphView] = useState(false)
  const [selectedManageGraph, setSelectedManageGraph] = useState(null)
  const [isAddUsersShowing, setIsAddUsersShowing] = useState(false)
  const [userSearchString, setUserSearchString] = useState('')
  const [filterRole, setFilterRole] = useState(null)

  function setPermissionsID(val) {
    _setPermissionsID(val)
    permissionsIDRef.current = val
  }

  const hasVKGAccess = () => {
    return (props.vkgDomain && (props.usersObj[props.user.uid]['role'] === 'admin' || props.usersObj[props.user.uid]['vkgAccess']))
  }

  useEffect(() => {
    setEntries(props.usersObj ?
      Object.keys(props.usersObj).sort((aId, bId) => {
        return (props.usersObj?.[aId]?.email || 'zzz').toLowerCase().localeCompare((props.usersObj?.[bId]?.email || 'zzz').toLowerCase())
      }).map((userId, i) => {
        const user = props.usersObj[userId]
        return (
          <TableEntry
            key={'user-' + i}
            content={[
              <span key='email' className='text-bright-blue'>
                {user['email']}
              </span>,
              <span key='date'>{user['datetimeCreated']}</span>,
              <Button
                key='role'
                theme={props.theme}
                className='role-button capitalize'
                type={8}
                text={Helpers.vkgAccessString(user)}
                icon={<Icon icon='arrow-right-outline' />}
                iconPosition='right'
                iconJustify='edge'
                disabled={userId === props.user?.uid}
                onClick={() => {
                  setPermissionsID(userId)
                  setPermissionsEmail(user.email)
                  setPermissionsUser(user)
                  setPermissionsModalShowing(true)
                }}
              ></Button>,
            ]}
          />
        )
      }) :
      [])

    if (props.usersObj && props.user) {
      const newCanAccessPermissions =
        props.usersObj?.[props.user.uid]?.role === 'admin' ||
        props.usersObj?.[props.user.uid]?.permissions?.setPermissions
      setCanAccessPermissions(newCanAccessPermissions)
    }
  }, [props.usersObj, props.user])

  const currentUser = props.user && props.users && props.users.filter((user) => user.email === props.user.email)[0]

  function changeUserPermissions(permissions, id, currentRole, vkgAccess) {
    const newUsersObj = props.usersObj
    newUsersObj[id].role = currentRole
    props.storePayload({usersObj: newUsersObj})
    // const url = process.env.REACT_APP_URL + 'update/user'
    const url = process.env.REACT_APP_URL + endpoints.updateUser
    const data = {
      userId: id,
      permissions: permissions,
      vkgAccess: Helpers.encodeVKGAccess(vkgAccess),
      role: currentRole,
    }
    const headers = {
      userId: props.user.uid,
      orgId: props.orgId,
      authKey: props.authKey,
    }
    return Helpers.fetchPostJsonWithStatus(url, data, headers)
  }

  const saveVKGPermissions = (value, id, currentRole, vkgAccess) => {
    setPermissionsModalShowing(false)
    changeUserPermissions(value, id, currentRole, vkgAccess)
        .then(async ([resPromise, status]) => {
          const res = await resPromise
          if (status >= 200 && status < 300) {
            props.storePayload({
              userMessage: `Permissions updated successfully${res.message ? ': '+res.message : '.'}`,
              notificationType: 2,
              notificationIcon: 'check',
            })
          } else {
            props.storePayload({
              userMessage: `Error updating permissions${res.message ? ': '+res.message : '.'}`,
              notificationType: 1,
              notificationIcon: 'warning',
            })
          }
        })
        .catch((error) => {
          console.log(error)
          props.storePayload({
            userMessage:
  'Error updating permissions.',
            notificationType: 1,
            notificationIcon: 'warning',
          })
        })
  }

  useEffect(() => {
    if (props.graphObj) {
      setGraphEntries(
          Object.keys(props.graphObj).sort().map(
              (vkgId) => {
                return (
                  <TableEntry
                    key={`${vkgId}-table-row`}
                    content={[
                      <Link
                        key={`${vkgId}-link`}
                        to={`${process.env.REACT_APP_VKG_UI}?vkgId=${vkgId}`}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <span key='vkgId' className='text-bright-green vkg-name'>
                          {vkgId}
                          <span className='link-center'><Icon icon='external-link-outline'/></span>
                        </span>
                      </Link>,
                      <span key='vkgSize' className=''>
                        {props.graphObj[vkgId].nodes}
                      </span>,
                      <div
                        key='vkgManage'
                        className='vkg-table-manage'
                      >
                        <Button
                          theme={props.theme}
                          text={'Manage Graph'}
                          width={'fit-content'}
                          type={6}
                          onClick={() => {
                            setIsManageGraphView(true)
                            setSelectedManageGraph(vkgId)
                          }}
                        />
                      </div>,
                    ]}
                  />
                )
              },
          ),
      )
    }
  }, [props.graphObj])

  function getManageRender(selectedManageGraph, usersObj) {
    return (
      <div
        className='manage-graph'
      >
        <div
          className='manage-graph-back-row'
          onClick={() => {
            setIsManageGraphView(false)
            setSelectedManageGraph(null)
          }}
        >
          <span><Icon icon={'arrow-back-outline'} /></span>
          <span>Back to graphs</span>
        </div>
        <div
          className='manage-graph-title'
        >
          Manage Graph
        </div>
        <div
          className='manage-graph-secondary'
        >
          See what users can access this graph.
        </div>
        <div
          className='manage-graph-select-row'
        >
          <div
            className='manage-graph-select-row-label'
          >
            Graph:
          </div>
          <Input
            className='manage-graph-select-row-input'
            theme={props.theme}
            inputType='select'
            placeholder={'VKG ID'}
            options={Object.keys(props.graphObj).sort()}
            value={selectedManageGraph}
            onChange={(e) => {
              setSelectedManageGraph(e.target.value)
            }}
            iconLeft={<Icon icon='list-outline' />}
          />
        </div>
        <Table
          title={<>
            <div
              className='manage-graph-table-title-row'
            >
              <div
                className='manage-graph-table-title'
              >
                {selectedManageGraph}
              </div>
              <Button
                text='Add User'
                iconLeft={<Icon icon={'plus-outline'} />}
                width={'fit-content'}
                theme={props.theme}
                onClick={() => {
                  setIsAddUsersShowing(true)
                }}
                disabled={!(props.usersObj[props.user.uid]?.permissions?.setPermissions || props.usersObj[props.user.uid]?.role === 'admin')}
              />
            </div>
            <div
              className='manage-graph-table-title-row'
            >
              <div className='manage-graph-table-title-row-wrapper'>
                <Input
                  type={2}
                  inputType={'select'}
                  placeholder={'Roles'}
                  iconLeft={<Icon icon={'list-outline'} />}
                  options={['All', 'Admin', 'Intermediate', 'Basic', 'Custom']}
                  value={Helpers.capitalizeFirstLetter(filterRole)}
                  theme={props.theme}
                  onChange={(e) => {
                    setFilterRole(e.target.value.toLowerCase())
                  }}
                />
              </div>
              <div className='manage-graph-table-title-row-wrapper'>
                <SearchBar
                  theme={props.theme}
                  searchValue={userSearchString}
                  onChange={(e) => {
                    setUserSearchString(e.target.value)
                  }}
                />
              </div>
            </div>
          </>}
          theme={props.theme}
          showCount={false}
          entries={Object.keys(usersObj).sort((aId, bId) => {
            return (usersObj?.[aId]?.email || 'zzz').toLowerCase().localeCompare((usersObj?.[bId]?.email || 'zzz').toLowerCase())
          }).sort((aId, bId) => {
            const aValueInter = usersObj?.[aId]?.email.toLowerCase().indexOf(userSearchString.toLowerCase())
            const bValueInter = usersObj?.[bId]?.email.toLowerCase().indexOf(userSearchString.toLowerCase())
            const aValue = aValueInter > -1 ? aValueInter : 1000
            const bValue = bValueInter > -1 ? bValueInter : 1000
            return aValue - bValue
          }).filter((uid) => {
            const vkgAccess = usersObj[uid]?.vkgAccess
            return (vkgAccess?.vkgs?.includes('read') || vkgAccess?.vkgs?.includes('write') ||
            vkgAccess?.[Helpers.encodeVKGId(selectedManageGraph)]?.includes('read') || vkgAccess?.[Helpers.encodeVKGId(selectedManageGraph)]?.includes('write')) &&
            (((filterRole && filterRole !== 'all') ? usersObj[uid]?.role?.toLowerCase() === filterRole.toLowerCase() : true))
          })
              .map((userId, i) => {
                const user = usersObj[userId]
                return (
                  <TableEntry
                    key={'user-' + i}
                    content={[
                      <span key='email' className='text-bright-blue'>
                        {user['email']}
                      </span>,
                      <span key='role-text'>{Helpers.vkgAccessString(user)}</span>,
                      <span key='role-text'>{user?.role}</span>,
                      <span key='date'>{user['datetimeCreated']}</span>,
                      <span
                        key='remove-vkg-access'
                      >
                        <Icon
                          icon='trash-2-outline'
                          className={(props.usersObj[props.user.uid]?.permissions?.setPermissions || props.usersObj[props.user.uid]?.role === 'admin') ?
                            'cursor-pointer remove-icon': 'invisible-btn'}
                          onClick={() => {
                            if ((props.usersObj[props.user.uid]?.permissions?.setPermissions ||
                              props.usersObj[props.user.uid]?.role === 'admin')) {
                              removeVKGAccess(userId, selectedManageGraph)
                            }
                          }}
                        />
                      </span>,
                    ]}
                  />
                )
              })}
          columnTitles={['Email', 'Access', 'Role', 'Date Added',
            <Icon
              icon='trash-2-outline'
              className={'invisible-btn'}
              key='header-icon-manage'
            />,
          ]}
          className={'vkg-manage-table'}
        />
      </div>
    )
  }

  function removeVKGAccess(userId, vkgId) {
    const user = props.usersObj[userId]
    const currentVKGAccess = user?.vkgAccess
    if (currentVKGAccess?.[Helpers.encodeVKGId(vkgId)]) { // has individual vkg access
      delete currentVKGAccess[Helpers.encodeVKGId(vkgId)]
    } else if (currentVKGAccess?.vkgs) {
      delete currentVKGAccess.vkgs
      if (user?.permissions?.writeVKG) {
        currentVKGAccess.vkgs = 'create'
      }
      const accessString = `${user.permissions?.readVKG ? 'read': ''}${user.permissions?.writeVKG ? ',write': ''}`
      // for all other vkgs add string
      if (accessString.length) {
        const accessVKGs = Object.keys(props.graphObj).filter((id) => id !== selectedManageGraph)
        for (let vIndex = 0; vIndex < accessVKGs.length; vIndex++) {
          currentVKGAccess[Helpers.encodeVKGId(accessVKGs[vIndex])] = accessString
        }
      }
    }
    props.usersObj[userId].vkgAccess = currentVKGAccess
    props.storePayload({
      usersObj: JSON.parse(JSON.stringify(props.usersObj)),
    })
    saveVKGPermissions(user?.permissions, userId, user?.role, currentVKGAccess)
  }

  function addVKGAccess(userIds, vkgId) {
    for (let uIndex = 0; uIndex < userIds.length; uIndex++) {
      const user = props.usersObj[userIds[uIndex]]
      const currentVKGAccess = Helpers.decodeVKGAccess(user?.vkgAccess)
      const accessString = `${user.permissions?.readVKG ? 'read': ''}${user.permissions?.writeVKG ? ',write': ''}`
      currentVKGAccess[vkgId] = accessString
      saveVKGPermissions(user?.permissions, userIds[uIndex], user?.role, currentVKGAccess)
    }
  }

  if (!hasVKGAccess()) {
    return <Navigate to='/' />
  }
  return (
    <div className='vkg-dashboard'>
      <PermissionSideTab
        email={permissionsEmail}
        id={permissionsID}
        permissionUser={permissionsUser}
        isVisible={permissionsModalShowing}
        onClose={() => {
          setPermissionsModalShowing(false)
        }}
        onSave={saveVKGPermissions}
      />
      <AddUsersSideTab
        isVisible={isAddUsersShowing}
        onClose={() => {
          setIsAddUsersShowing(false)
        }}
        onSave={(users) => {
          addVKGAccess(users, selectedManageGraph)
        }}
      />
      <TabContainer
        theme={props.theme}
        className='tabs'
        activeTab={activeTab}
        canChangeTab={() => true}
        onTabChange={(tabName) => {
          setActiveTab(tabName)
        }}
        tabContent={{
          graphs: {
            render: <>
              {isManageGraphView ?
              <>
                {getManageRender(selectedManageGraph, props.usersObj)}
              </>:
              <div className='tab-container vkg-metrics-wrapper'>
                <div className='vkg-metrics'>
                  {props.vkgMetrics &&
                  <>
                    <Card
                      theme={props.theme}
                      title='Total Queries'
                      className={'card'}
                    >
                      {
                        props.vkgMetrics ? Object.keys(props.vkgMetrics['num_queries'])
                            .map((graphId) => props.vkgMetrics['num_queries'][graphId])
                            .reduce((accumulator, currentValue) => {
                              return accumulator + currentValue
                            }, 0) :
                            'Loading...'
                      }
                    </Card>
                    <Card
                      theme={props.theme}
                      title='Total Nodes'
                      className={'card'}
                    >
                      {props.vkgMetrics?.['num_nodes'] || 'Loading...'}
                    </Card>
                    <Card
                      theme={props.theme}
                      title='Total Graphs'
                      className={'card'}
                    >
                      {props.vkgMetrics?.['num_vkgs'] || 'Loading...'}
                    </Card>
                  </>
                  }
                </div>
                <Table
                  theme={props.theme}
                  entries={graphEntries}
                  title='Active Graphs'
                  columnTitles={['VKG ID', 'Nodes', <Button // TODO: add num queries
                    key='spacing-button'
                    theme={props.theme}
                    text={'Manage Graph'}
                    width={'fit-content'}
                    type={6}
                    className={'invisible-btn'}
                  />]}
                  className={'vkg-graph-table'}
                >
                </Table>
              </div>
              }
            </>,
            isDisabled: false,
            title: 'Graphs',
          },
          permissions: {
            render: <div className='tab-container permissions-section'>
              <Table
                theme={props.theme}
                title='Your VKG Permissions'
                className='user-table hide-user-table-scroll'
                columnTitles={['Email', 'Role', 'Access']}
                showCount={false}
                entries={[
                  <TableEntry
                    key={'user'}
                    content={[
                      <span key='email' className='text-bright-blue'>
                        {currentUser && currentUser.email }
                      </span>,
                      <span key='email' className='text-success-light capitalize'>
                        {currentUser && currentUser.role}
                      </span>,
                      <span
                        key='access'
                        className={'text-success-light'}
                      >
                        {Helpers.vkgAccessString(currentUser)}
                      </span>,
                    ]}
                  />,
                ]}
                maxHeight='18rem'
              />
              <Table
                className=''
                theme={props.theme}
                title='Organization VKG Permission'
                total={0}
                columnTitles={['Email', 'Access', 'Change Access']}
                entries={entries}
              />
            </div>,
            isDisabled: !canAccessPermissions,
            title: 'Permissions',
          },
        }}
      />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  database: state.firebaseReducer.database,
  usersObj: state.userReducer.usersObj,
  users: state.userReducer.users,
  authKey: state.userReducer.authKey,
  userData: state.userReducer.userData,
  user: state.userReducer.user,
  orgDetails: state.userReducer.orgDetails,
  theme: state.userReducer.theme,
  orgId: state.userReducer.orgId,
  vkgDomain: state.userReducer.vkgDomain,
  graphObj: state.userReducer.graphObj,
  vkgMetrics: state.userReducer.vkgMetrics,
  authKey: state.userReducer.authKey,
})

export default connect(mapStateToProps, {
  getUsers,
  storePayload,
  // getBilling,
})(VKGDashboard)
