import React from 'react'
import {useLocation} from 'react-router-dom'
import {connect} from 'react-redux'
import {storePayload} from '../actions/storePayload.js'
import {logAnalyticsEvent} from '../actions/logAnalyticsEvent.js'
import {signOut} from '../actions/signOut.js'
import '../styles/Nav.css'
import {VariableNav} from '@lazarusai/forms-ui-components'

function Nav(props) {
  const location = useLocation()

  const handleExpand = () => {
    document.body.classList.add('nav-expanded')
  }

  const handleCollapse = () => {
    document.body.classList.contains('nav-expanded') &&
      document.body.classList.remove('nav-expanded')
  }

  const endpointAccessDict = {
  // list of required permissions for each endpoint:
    'dashboard': [
      'viewLogs',
    ],
    'api': [
      'viewLogs',
      'viewCredentials',
    ],
    'quickstart': [
      'viewCredentials',
    ],
    // 'settings': [
    //   'viewCredentials',
    //   'manageBilling',
    //   'resetAuthKey',
    //   // gate-keep invite user
    //   // gate-keep set permissions
    // ],
  }

  function hasAccess(endpoint) {
    // check for all permissions for given endpoint
    if (props.usersObj) {
      const userData = props.usersObj[props.user.uid]
      if (userData) {
        return !endpointAccessDict[endpoint] || endpointAccessDict[endpoint].every((permission) => {
          return userData.role === 'admin' || userData?.permissions?.[permission]
        })
      } else {
        return true
      }
    }
  }

  function showSupport(orgDetails) {
  // Show support if the orgDetails don't exist
  // or if orgDetails.disconnectSupport is null / false
    return !orgDetails?.disconnectSupport
  }

  // function showTraining(orgDetails) {
  //   return orgDetails?.permissions?.canUploadTrainingData
  // }

  // function rikaiPermission(usersObj, user) {
  //   return props.usersObj?.[props.user.uid]?.role === 'admin' || usersObj?.[user?.uid]?.permissions?.['useDemoCredits']
  // }

  // the link will still take them to calendly request link
  // !rikaiPermission(props.usersObj, props.user) && disabledLinks.push('rikai')

  const checkChanges = (e, destination) => {
    if (
      (props.profileChangesMade || props.isUsingDemo) &&
      // same endpoint
      (
        destination === '/' ? // going to root location
            location.pathname !== destination : // already at root
            !location.pathname.includes(destination) // from some other location
      ) &&
      // not links to separate urls that will open new tab
      !destination.includes('https://')
    ) {
      if (window.confirm('Your progress will not be saved. Are you sure you want to continue?')) {
        props.storePayload({
          profileChangesMade: false,
          isUsingDemo: false,
        })
        window.removeEventListener('beforeunload', props.preventNavigationListener)
      } else {
        e.preventDefault()
        return false
      }
    }
    return true
  }

  const hasVKGAccess = () => {
    return (props.vkgDomain && (props.usersObj[props.user.uid]['vkgAccess'] || props.usersObj[props.user.uid]['role'] === 'admin'))
  }
  // const hasSubOrgAccess = () => {
  //   return (props.usersObj[props.user.uid]['role'] === 'admin' || props.usersObj[props.user.uid]['permissions']?.['controlSubOrgs'])
  // }

  return (
    <div>
      <VariableNav
        {...props}
        isVisible={true}
        links={
          [
            {name: 'Dashboard', icon: 'flash', path: hasAccess('dashboard') ? '/' : '/demo', isDisabled: !hasAccess('dashboard')},
            {name: 'API', icon: 'cube', path: hasAccess('api') ? '/api' : '/', isDisabled: !hasAccess('api')},
            // {name: 'Training', icon: 'book', path: showTraining(props.orgDetails) ? '/training' : '/', isDisabled: !showTraining(props.orgDetails)},
            {name: 'Quickstart', icon: 'arrowhead-right', path: hasAccess('quickstart') ? '/quickstart' : '/', isDisabled: !hasAccess('quickstart')},
            {name: 'Models', icon: 'grid', path: '/models'},
            {name: 'VKG', icon: 'globe', path: hasVKGAccess() ? '/vkg' : '/', isDisabled: !hasVKGAccess()},
            // {name: 'Sub Organizations', icon: 'bar-chart', path: hasSubOrgAccess() ? '/suborganizations' : '/', isDisabled: !hasSubOrgAccess()},
            {name: 'Demo', icon: 'gift', path: '/demo', isDisabled: false, highlight: true},
            {name: 'Support', icon: 'alert-circle', path: !showSupport(props.orgDetails) ? '/' : 'https://lazarus-ai.atlassian.net/servicedesk/customer/portal/5', isDisabled: !showSupport(props.orgDetails), outsideLink: true},
            {name: 'Documentation', icon: 'code', path: 'https://docs.lazarusforms.com/docs/lazarus-forms/welcome', isDisabled: false, outsideLink: true},
          ]
        }
        bottomLinks={
          [
            {name: 'Settings', icon: 'settings-2', path: '/settings', isDisabled: false},
          ]
        }
        onSignOutClick={(e) => {
          props.signOut(props.orgId)
        }}
        onExpand={handleExpand}
        onCollapse={handleCollapse}
        currentEndpoint={location.pathname}
        onClick={checkChanges}
        theme={props.theme}
        userName={(props.userData.firstName || props.userData.lastName) ? `${props.userData.firstName}${(props.userData.firstName && props.userData.lastName) ? ' ': ''}${props.userData.lastName}` : props.user.email}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  logs: state.userReducer.logs,
  isMenu: state.userReducer.isMenu,
  isTracking: state.userReducer.isPostTutorialTracking,
  orgId: state.userReducer.orgId,
  analytics: state.firebaseReducer.analytics,
  usersObj: state.userReducer.usersObj,
  user: state.userReducer.user,
  orgDetails: state.userReducer.orgDetails,
  theme: state.userReducer.theme,
  userData: state.userReducer.userData,
  profileChangesMade: state.userReducer.profileChangesMade,
  preventNavigationListener: state.userReducer.preventNavigationListener,
  vkgDomain: state.userReducer.vkgDomain,
  isUsingDemo: state.userReducer.isUsingDemo,
})

export default connect(
    mapStateToProps,
    {signOut, storePayload, logAnalyticsEvent},
)(Nav)
