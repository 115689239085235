import React from 'react'
import {Chart, Card} from '@lazarusai/forms-ui-components'
import {connect} from 'react-redux'
import '../styles/ApiUsageChart.css'
import '@lazarusai/forms-ui-components/dist/index.css'

function ApiUsageChart({metrics, theme, maxHeight = '100%', aspectRatio}) {
  return (
    <Card title='API Usage' theme={theme} className='api-usage'>
      {metrics && (
        <Chart
          debug={true}
          theme={theme}
          data={metrics}
          onChartClick={(e) => {
            console.log(e)
          }}
          maxHeight={maxHeight}
          aspectRatio={aspectRatio}
        />
      )}
    </Card>
  )
}

const mapStateToProps = (state, ownProps) => ({
  cookies: ownProps.cookies,
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  userMessage: state.userReducer.userMessage,
  notificationType: state.userReducer.notificationType,
  notificationIcon: state.userReducer.notificationIcon,
  isSignedIn: state.userReducer.isSignedIn,
  isNew: state.userReducer.isNew,
  metrics: state.userReducer.metrics,
  theme: state.userReducer.theme,
})

export default connect(mapStateToProps)(ApiUsageChart)
