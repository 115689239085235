import React, {useEffect, useState, useRef, Fragment} from 'react'
import {connect} from 'react-redux'
import {Navigate, useParams} from 'react-router-dom'

import {Table, TableEntry, Button, Icon, Input, Card, Select, Switch} from '@lazarusai/forms-ui-components'
import PermissionsModal from './PermissionsModal'

import Helpers from '../Helpers'
import {getUsers} from '../actions/getUsers'
import {storePayload} from '../actions/storePayload'
import endpoints from '../endpoints.json'
import blockedEmails from '../blockedEmails.json'
import '../styles/SubOrgView.css'
import {getSubOrganizationDetails} from '../actions/getSubOrganizationDetails'
import {setSubOrgModelAccessPricing} from '../actions/setSubOrgModelAccessPricing'
import {setSubOrgLimits} from '../actions/setSubOrgLimits'
import {getSubOrganizationAuthKey} from '../actions/getSubOrganizationAuthKey'
import {resetSubOrganizationAuthKey} from '../actions/resetSubOrganizationAuthKey'
import {getSubOrgBillingHistory} from '../actions/getSubOrgBillingHistory'
import {updateSubOrgCreatePermission} from '../actions/updateSubOrgCreatePermission'

function SubOrgView(props) {
  const [subOrgUserEntries, setSubOrgUserEntries] = useState([])
  const [permissionsModalShowing, setPermissionsModalShowing] = useState(false)
  const [permissionsID, _setPermissionsID] = useState(null)
  const [permissionsDict, setPermissionsDict] = useState({})
  const permissionsIDRef = useRef(null)

  function setPermissionsID(val) {
    _setPermissionsID(val)
    permissionsIDRef.current = val
  }

  const [permissionsEmail, setPermissionsEmail] = useState(null)
  const [permissionsUser, setPermissionsUser] = useState(null)
  const [inviteEmail, setInviteEmail] = useState('')
  const [subOrgModelAccess, setSubOrgModelAccess] = useState([])
  const [subOrgModelPricing, setSubOrgModelPricing] = useState({})
  const [subOrgLimits, setSubOrgLimits] = useState({})
  const [isSubOrgAuthKeyShowing, setIsSubOrgAuthKeyShowing] = useState(false)
  const [isOrgIdCopied, setIsOrgIdCopied] = useState(false)
  const [isAuthKeyCopied, setIsAuthKeyCopied] = useState(false)
  const [subOrgCanCreateSubOrg, setSubOrgCanCreateSubOrg] = useState(undefined)
  const [confirmResetAuthKey, setConfirmResetAuthKey] = useState(false)

  const hiddenString = '*************'
  const periodOptions = ['month', 'day', 'year', 'future', 'reset']

  const onClickCopyOrgId = () => {
    Helpers.copyToClipBoard(subOrgId, () => {
      setIsOrgIdCopied(true)
    })

    setTimeout(() => {
      setIsOrgIdCopied(false)
    }, 2000)
  }

  const onClickCopyAuthKey = () => {
    Helpers.copyToClipBoard(props.subOrgAuthKey, () => {
      setIsAuthKeyCopied(true)
    })

    setTimeout(() => {
      setIsAuthKeyCopied(false)
    }, 2000)
  }

  function showAuthKey() {
    setIsSubOrgAuthKeyShowing(!isSubOrgAuthKeyShowing)
    if (!props.subOrgAuthKey) {
      // GET AUTH KEY
      props.getSubOrganizationAuthKey(props.orgId, props.authKey, props.user.uid, subOrgId)
    }
  }

  useEffect(() => {
    // Run Info Call
    props.storePayload({
      subOrganizationDetails: null,
      subOrgAuthKey: undefined,
    })
    props.getSubOrganizationAuthKey(props.orgId, props.authKey, props.user.uid, subOrgId)
    props.getSubOrganizationDetails(props.orgId, props.authKey, props.user.uid, subOrgId)
  }, [])

  useEffect(() => {
    const userDefaultPermissions = props.usersObj?.[permissionsIDRef.current]?.permissions
    const perms = {
      viewLogs: {
        roles: ['basic', 'intermediate', 'admin'],
        description: 'View basic log data',
        icon: <Icon icon='star-outline'></Icon>,
        default: userDefaultPermissions?.['viewLogs'],
      },
      viewLogData: {
        roles: ['intermediate', 'admin'],
        description: 'Download log data',
        icon: <Icon icon='settings-outline'></Icon>,
        default: userDefaultPermissions?.['viewLogData'],
      },
      useDemoCredits: {
        roles: ['intermediate', 'admin'],
        description: 'Use demo credits',
        icon: <Icon icon='settings-outline'></Icon>,
        default: userDefaultPermissions?.['useDemoCredits'],
      },
      viewCredentials: {
        roles: ['intermediate', 'admin'],
        description: 'View credentials',
        icon: <Icon icon='flash-outline'></Icon>,
        default: userDefaultPermissions?.['viewCredentials'],
      },
      manageBilling: {
        roles: ['admin'],
        description: 'View/manage billing',
        icon: <Icon icon='flash-outline'></Icon>,
        default: userDefaultPermissions?.['manageBilling'],
      },
      inviteUser: {
        roles: ['admin'],
        description: 'Invite new users',
        icon: <Icon icon='flash-outline'></Icon>,
        default: userDefaultPermissions?.['inviteUser'],
      },
      setPermissions: {
        roles: ['admin'],
        description: 'Set user permissions',
        icon: <Icon icon='flash-outline'></Icon>,
        default: userDefaultPermissions?.['setPermissions'],
      },
      resetAuthKey: {
        roles: ['admin'],
        description: 'Reset authKey',
        icon: <Icon icon='flash-outline'></Icon>,
        default: userDefaultPermissions?.['resetAuthKey'],
      },
      viewAuditLogs: {
        roles: ['admin'],
        description: 'View audit log data',
        icon: <Icon icon='flash-outline'></Icon>,
        default: userDefaultPermissions?.['viewAuditLogs'],
      },
      controlSubOrgs: {
        roles: ['admin'],
        description: 'Control Sub Org access/billing/.etc',
        icon: <Icon icon='flash-outline'></Icon>,
        default: userDefaultPermissions?.['controlSubOrgs'],
      },
      readVKG: {
        roles: ['basic', 'intermediate', 'admin'],
        description: 'Read/search through VKGs',
        icon: <Icon icon='eye-outline'></Icon>,
        default: userDefaultPermissions?.['readVKG'],
      },
      writeVKG: {
        roles: ['admin'],
        description: 'Write/delete/create VKG data and new graphs',
        icon: <Icon icon='edit-outline'></Icon>,
        default: userDefaultPermissions?.['writeVKG'],
      },
      // removeAccounts: {
      //   roles: ['admin'],
      //   description: 'Remove account',
      //   icon: <Icon icon='flash-outline'></Icon>,
      //   default: userDefaultPermissions?.['removeAccounts'],
      // },
    }
    if (!props.vkgDomain) {
      delete perms.readVKG
      delete perms.writeVKG
    }
    if (!props.orgDetails?.permissions?.canCreateSubOrg) {
      delete perms.controlSubOrgs
    }
    setPermissionsDict(perms)
  }, [props.vkgDomain, permissionsID])

  const isBusinessEmail = (email) => {
    return !blockedEmails.blockedEmails.some((emailProvider) => email.includes(emailProvider))
  }

  function onChangeForm(e) {
    if (!isBusinessEmail(e.target.value)) {
      props.storePayload({
        userMessage: 'Users must have business emails',
        notificationType: 2,
        notificationIcon: 'warning',
      })
    }
    setInviteEmail(e.target.value)
  }

  function onClickSendInvite(e) {
    e.preventDefault()
    setPermissionsID(inviteEmail)
    setPermissionsEmail(inviteEmail)
    /* clear user if stored from existing user permission change
      (same modal rendered for invite and existing user permission change)
    */
    setPermissionsUser(null)
    setPermissionsModalShowing(true)
  }

  useEffect(() => {
    if (props.subOrganizationDetails?.userRoster) {
      setSubOrgUserEntries(
          Object.keys(props.subOrganizationDetails?.userRoster)
              .map((userId) => {
                return (
                  <TableEntry
                    key={`user-row-${userId}`}
                    content={[
                      <span key='email' className='text-bright-blue'>
                        {props.subOrganizationDetails.userRoster[userId]?.email || ''}
                      </span>,
                      <span key='date'>{props.subOrganizationDetails.userRoster[userId]?.datetimeCreated}</span>,
                      <Button
                        key='role'
                        theme={props.theme}
                        className='role-button capitalize'
                        type={8}
                        text={props.subOrganizationDetails.userRoster[userId]?.['role']}
                        icon={<Icon icon='arrow-right-outline' />}
                        iconPosition='right'
                        iconJustify='edge'
                        disabled={userId === props.user?.uid}
                        onClick={() => {
                          setPermissionsID(userId)
                          setPermissionsEmail(props.subOrganizationDetails.userRoster[userId]?.email)
                          setPermissionsUser(props.subOrganizationDetails.userRoster[userId])
                          setPermissionsModalShowing(true)
                        }}
                      ></Button>,
                    ]}
                  />
                )
              }),
      )
    }
    initializeModelControlState()
    initializeLimitState()
    initializeCanCreateSubOrg()
  }, [props.subOrganizationDetails])

  function initializeModelControlState(hardReset = false) {
    if (props.subOrganizationDetails?.models && (!subOrgModelAccess.length || hardReset)) {
      setSubOrgModelAccess(Object.keys(props.subOrganizationDetails?.models))
    } else if (!props.subOrganizationDetails?.models) {
      setSubOrgModelAccess([])
    }
    if (props.subOrganizationDetails?.billing?.pricing && (!Object.keys(subOrgModelPricing).length || hardReset)) {
      const pricing = props.subOrganizationDetails?.billing?.pricing
      const priceKeys = Object.keys(pricing)
      for (let pIndex = 0; pIndex < priceKeys.length; pIndex++) {
        pricing[priceKeys[pIndex]].isSetting = true
      }
      setSubOrgModelPricing(hardReset ? Object.assign({}, pricing || {}) : Object.assign({}, pricing || {}, subOrgModelPricing))
    } else if (!props.subOrganizationDetails?.billing?.pricing) {
      setSubOrgModelPricing({})
    }
  }

  function getDetailsLimit(hardReset) {
    const newSubOrgLimits = JSON.parse(JSON.stringify(hardReset ? {} : subOrgLimits))
    if (props.subOrganizationDetails?.billing?.creditLimit && !(hardReset ? {} : subOrgLimits)?.creditLimit) {
      newSubOrgLimits.creditLimit = props.subOrganizationDetails?.billing?.creditLimit
      delete newSubOrgLimits.creditLimit.creditUsage
    }
    if (props.subOrganizationDetails?.billing?.pageLimit && !(hardReset ? {} : subOrgLimits)?.pageLimit) {
      newSubOrgLimits.pageLimit = props.subOrganizationDetails?.billing?.pageLimit
      delete newSubOrgLimits.pageLimit.pageUsage
    }
    if (props.subOrganizationDetails?.billing?.modelLimit && !(hardReset ? {} : subOrgLimits)?.modelLimit) {
      newSubOrgLimits.modelLimit = Object.assign({},
          ...Object.keys(props.subOrganizationDetails?.billing?.modelLimit)
              .map((modelId) => {
                const retObj = {}
                retObj[modelId] = Object.assign({},
                    ...Object.keys(props.subOrganizationDetails?.billing?.modelLimit?.[modelId])
                        .filter((key) => !key.includes('Usage'))
                        .map((key) => {
                          const retKeyObj = {}
                          retKeyObj[key] = props.subOrganizationDetails?.billing?.modelLimit?.[modelId]?.[key]
                          return retKeyObj
                        }))
                return retObj
              }))
      // pain
    }
    return newSubOrgLimits
  }

  function initializeLimitState(hardReset = false) {
    const newSubOrgLimits = getDetailsLimit(hardReset)
    if (JSON.stringify(newSubOrgLimits) !== JSON.stringify(subOrgLimits)) {
      setSubOrgLimits(newSubOrgLimits)
    }
  }

  function initializeCanCreateSubOrg(hardReset = false) {
    const currentValue = props.subOrganizationDetails?.orgPermissions?.canCreateSubOrg
    if ((currentValue !== undefined && subOrgCanCreateSubOrg === undefined) || hardReset) {
      setSubOrgCanCreateSubOrg(currentValue)
    }
  }

  function changeUserPermissions(permissions, id, currentRole) {
    // const url = process.env.REACT_APP_URL + 'update/user'
    const url = process.env.REACT_APP_URL + endpoints.updateUser
    const data = {
      userId: id,
      subOrgId: subOrgId,
      permissions: permissions,
      role: currentRole,
    }
    const headers = {
      userId: props.user.uid,
      orgId: props.orgId,
      authKey: props.authKey,
      subOrgId: subOrgId,
    }
    return Helpers.fetchPostJsonWithStatus(url, data, headers)
  }

  const savePermissions = (value, id, currentRole) => {
    setPermissionsModalShowing(false)

    if (id === inviteEmail && inviteEmail.length > 0) {
      // const url = process.env.REACT_APP_URL + 'invite'
      const url = process.env.REACT_APP_URL + endpoints.invite
      const data = {
        subOrgId: subOrgId,
        email: inviteEmail,
        role: currentRole.toLowerCase(),
        permissions: value,
      }
      const headers = {
        userId: props.user.uid,
        orgId: props.orgId,
        authKey: props.authKey,
        subOrgId: subOrgId,
      }
      Helpers.fetchPostJsonWithStatus(url, data, headers)
          .then(async ([resPromise, status]) => {
            const data = await resPromise
            if (status >= 200 && status < 300) {
              props.storePayload({
                userMessage:
        `User was successfully added. They should receive an email with a link to reset their password${data.error ? ': '+Helpers.parseErrorMessages(data.error).join(', ') : '.'}`,
                notificationType: 1,
                notificationIcon: 'check',
              })
              setInviteEmail('')
            } else {
              props.storePayload({
                userMessage: `Error inviting user${data.error ? ': '+Helpers.parseErrorMessages(data.error).join(', ') : '.'}`,
                notificationType: 2,
                notificationIcon: 'warning',
              })
            }
          })
          .catch((data) => {
            console.log(data)
            props.storePayload({
              userMessage: 'Error inviting user.',
              notificationType: 2,
              notificationIcon: 'warning',
            })
          })
    } else { // regular permission change
      changeUserPermissions(value, id, currentRole.toLowerCase())
          .then(async ([resPromise, status]) => {
            const res = await resPromise
            if (status >= 200 && status < 300) {
              props.storePayload({
                userMessage: `Permissions updated successfully${res.message ? ': '+res.message : '.'}`,
                notificationType: 2,
                notificationIcon: 'check',
              })
            } else {
              props.storePayload({
                userMessage: `Error updating permissions${res.message ? ': '+res.message : '.'}`,
                notificationType: 1,
                notificationIcon: 'warning',
              })
            }
          })
          .catch((error) => {
            console.log(error)
            props.storePayload({
              userMessage:
    'Error updating permissions.',
              notificationType: 1,
              notificationIcon: 'warning',
            })
          })
    }
  }

  const {subOrgId} = useParams()
  const hasSubOrgAccess = () => {
    if (props.subOrganizationMetrics && !props.subOrganizationMetrics?.[subOrgId]) {
      return false
    }
    return (subOrgId !== props.orgId) && (props.usersObj[props.user.uid]['role'] === 'admin' || props.usersObj[props.user.uid]['permissions']['canCreateSubOrg'])
  }

  const pricingStateToOutput = (pricingState) => {
    return Object.assign({}, ...Object.keys(pricingState || {}).sort()
        .map((modelId) => {
          const modelPrice = JSON.parse(JSON.stringify(pricingState[modelId]))
          delete modelPrice.isSetting
          const output = {}
          output[modelId] = modelPrice
          return output
        }),
    )
  }

  const hasModelAccessOrPricingChanged = (subOrgModelAccess, subOrgModelPricing) => {
    // Check Model Access
    if (subOrgModelAccess.length !== Object.keys(props.subOrganizationDetails?.models || {}).length ||
      JSON.stringify(subOrgModelAccess.sort()) !== JSON.stringify(Object.keys(props.subOrganizationDetails?.models || {}).sort())
    ) {
      return true
    } else if (JSON.stringify(Object.keys(props.subOrganizationDetails?.billing?.pricing || {}).sort()) !== JSON.stringify(Object.keys(subOrgModelPricing || {}).sort()) ||
      JSON.stringify(pricingStateToOutput(subOrgModelPricing)) !== JSON.stringify(pricingStateToOutput(props.subOrganizationDetails?.billing?.pricing))
    ) {
      return true
    }
    return false
  }

  const haveLimitsChanged = (subOrgLimits) => {
    const checkLimits = JSON.parse(JSON.stringify(subOrgLimits))
    if (JSON.stringify(Helpers.removeBadKVPs(checkLimits)) !== JSON.stringify(getDetailsLimit(true))) {
      return true
    }
    return false
  }

  // Permission Wrapper for org level permission check
  const canChangeSubOrg = (orgDetails) => {
    return orgDetails?.permissions?.canCreateSubOrg || false
  }

  function getBillingHistory() {
    props.storePayload({
      userMessage: 'Getting billing history...',
      notificationType: 1,
      notificationIcon: 'check',
    })
    props.getSubOrgBillingHistory(props.orgId, props.authKey, props.user.uid,
        subOrgId, props.subOrganizationDetails.orgName)
  }

  if (!hasSubOrgAccess()) {
    return <Navigate to='/' />
  }
  return (
    <div className='sub-org-view show-scrollbar'>
      {permissionsModalShowing &&
        <PermissionsModal
          id={permissionsID}
          user={Helpers.truncateString(permissionsEmail, 30)}
          onSave={savePermissions}
          isVisible={permissionsModalShowing}
          onCancel={() => {
            setPermissionsModalShowing(false)
          }}
          roleDescriptions={''}
          theme={props.theme}
          roles={['basic', 'intermediate', 'admin']}
          permissions={permissionsDict}
          permissionsUser={permissionsUser}
        />
      }
      {props.subOrganizationDetails &&
        <>
          <div className='sub-org-header-row'>
            <div className='sub-org-header-half'>
              <div className='sub-org-header-name'>
                {props.subOrganizationDetails.orgName}
              </div>
              <div className='sub-org-header-id'>
                {subOrgId}
              </div>
            </div>
            <div className='sub-org-header-half'>
              <div className='sub-org-header-parent-label'>
              Parent
              </div>
              <div className='sub-org-header-parent'>
                <span>{`${props.subOrganizationDetails?.parentOrgName} - ${props.subOrganizationDetails?.parentOrgId}`}</span>
              </div>
            </div>
            <Button
              theme={props.theme}
              className={'sub-org-billing-button'}
              text='Billing History'
              icon={<Icon icon='download-outline' />}
              iconPosition='right'
              iconJustify='edge'
              download={true}
              onClick={getBillingHistory}
            />
          </div>
        </>
      }{props.subOrganizationDetails &&
        <div className='sub-org-row'>
          <div className='sub-org-row-title'>
            Credentials
          </div>
          <div
            className='sub-org-row-body sub-org-row-spacing sub-org-split-row'
          >
            <Card title='Org Id' theme={props.theme} className='background-salem'>
              <p className='card-title text-bright-green'>
                {subOrgId}
              </p>
              <div data-test-id='orgId-container' className='gap-group gap-group-columns gap-group-columns-auto justify-start'>
                <div
                  className='flex'
                >
                </div>
                <div className='flex cursor-pointer' onClick={onClickCopyOrgId}>
                  <div
                    className={`toggle-container ${isOrgIdCopied ? 'toggled' : ''}`}
                  >
                    <Icon icon='checkmark-outline' />
                    <Icon icon='copy-outline' />
                  </div>
                  <span>{isOrgIdCopied ? 'Copied' : 'Copy to Clipboard'}</span>
                </div>
              </div>
            </Card>
            <Card title='Auth Key' theme={props.theme} className='background-salem'>
              <p className='card-title text-bright-green'>
                {isSubOrgAuthKeyShowing ? (props.subOrgAuthKey || 'Loading...') : hiddenString}
              </p>
              <div data-test-id='authKey-container' className='gap-group gap-group-columns gap-group-columns-auto justify-start'>
                <div
                  className='flex cursor-pointer'
                  onClick={showAuthKey}
                >
                  <div
                    className={`toggle-container ${
                    !isSubOrgAuthKeyShowing ? 'toggled' : ''
                    }`}
                  >
                    <Icon icon='eye-outline' />
                    <Icon icon='eye-off-2-outline' />
                  </div>
                  <span>{isSubOrgAuthKeyShowing ? 'Hide' : 'Show'}</span>
                </div>
                {isSubOrgAuthKeyShowing && props.subOrgAuthKey &&
                <div className='flex cursor-pointer' onClick={onClickCopyAuthKey}>
                  <div
                    className={`toggle-container ${isAuthKeyCopied? 'toggled' : ''}`}
                  >
                    <Icon icon='checkmark-outline' />
                    <Icon icon='copy-outline' />
                  </div>
                  <span>{isAuthKeyCopied ? 'Copied' : 'Copy to Clipboard'}</span>
                </div>
                }
              </div>
            </Card>
          </div>
        </div>
      }
      {props.subOrganizationDetails &&
        <div className='sub-org-row'>
          <div className='sub-org-row-title'>
            Invite Users
          </div>
          <div className='sub-org-row-body'>
            <Card
              theme={props.theme}
            >
              <form onSubmit={onClickSendInvite}>
                <Input
                  name='inviteeEmail'
                  onChange={onChangeForm}
                  value={inviteEmail}
                  theme={props.theme}
                  label='Email'
                  type='email'
                  iconLeft={<Icon icon='person-outline' />}
                  placeholder="Type User's Email"
                  required
                />
                <Button
                  buttonType='submit'
                  text='Next'
                  theme={props.theme}
                  icon={<Icon icon='arrow-forward-outline' />}
                  iconPosition='right'
                  iconJustify='edge'
                  disabled={!isBusinessEmail(inviteEmail)}
                />
              </form>
            </Card>
          </div>
        </div>
      }
      {props.subOrganizationDetails?.userRoster &&
        <div className='sub-org-row'>
          <div className='sub-org-row-title'>
            User Roster
          </div>
          <div className='sub-org-row-body'>
            <Table
              showTotal={(subOrgUserEntries && subOrgUserEntries.length) ? true : false}
              columnTitles={['User', 'Date Created', 'Role']}
              tableId={'sub-org-user-table'}
              className={'org-table'}
              entries={subOrgUserEntries === null ?
            [<TableEntry
              key={'loading-row'}
              content={['Sub Organization Users Loading']}
            />] :
            subOrgUserEntries.length ?
              subOrgUserEntries :
              [<TableEntry
                key={'no-sub-row'}
                content={['No Sub Organizations Users']}
              />]
              }
              theme={props.theme}
            />
          </div>
        </div>
      }
      {props.subOrganizationDetails &&
        <div className='sub-org-row'>
          <div className='sub-org-row-title'>
            Model Usage
          </div>
          {(props.subOrganizationDetails?.models && Object.keys(props.subOrganizationDetails?.models).length) ?
            <Table
              columnTitles={['Model', 'Model Id', 'Successful Requests', 'Total Pages', 'Total Questions', 'Last Used']}
              className={'sub-org-row-body org-table'}
              showTotal={false}
              theme={props.theme}
              entries={Object.keys(props.subOrganizationDetails?.models)
                  .sort((aModelId, bModelId) => {
                    return props.subOrganizationDetails?.models[bModelId]['total_pages'] - props.subOrganizationDetails?.models[aModelId]['total_pages']
                  })
                  .map((modelId) => {
                    return (
                      <TableEntry
                        key={`model-${modelId}`}
                        content={[
                          props.modelInformation[modelId]?.public?.metadata?.name,
                          modelId,
                          props.subOrganizationDetails?.models[modelId]['successful_requests'],
                          props.subOrganizationDetails?.models[modelId]['total_pages'],
                          props.subOrganizationDetails?.models[modelId]['total_questions'],
                          (props.subOrganizationDetails?.models[modelId]['last_used'] || ''),
                        ]}
                      />
                    )
                  })}
            /> :
            <div className='sub-org-row-body'>No Model Access</div>
          }
        </div>
      }
      {props.subOrganizationDetails &&
        <div className='sub-org-row'>
          <div className='sub-org-row-title'>
            Model Access and Pricing
            {!canChangeSubOrg(props.orgDetails) && <span className='view-only'>{' View Only'}</span>}
            {hasModelAccessOrPricingChanged(subOrgModelAccess, subOrgModelPricing) && <>
              <span className='unsaved-text'>{'   Unsaved Changes   '}</span>
              <span
                onClick={() => initializeModelControlState(true)}
                className='reset-text'
              >Reset Changes</span>
            </>}
          </div>
          <Card
            theme={props.theme}
            className='sub-org-row-body sub-org-row-spacing'
          >
            <Select
              value={subOrgModelAccess.map((modelId) => `${modelId} - ${props.modelInformation[modelId]?.public?.metadata?.name}`)}
              label='Model Access'
              theme={props.theme}
              isSelectedYellow={true}
              options={props.modelAccess ? props.modelAccess.
                  filter((modelId) => props.modelInformation[modelId]?.public && !['genericFormApi2', 'invoices', 'receipts'].includes(modelId)).
                  map((modelId) => `${modelId} - ${props.modelInformation[modelId]?.public?.metadata?.name}`) :
                []}
              isMultiselect={true}
              onClickOption={(option) => {
                if (canChangeSubOrg(props.orgDetails)) {
                  if (subOrgModelAccess.includes(option.split(' - ')[0])) {
                    setSubOrgModelAccess(JSON.parse(JSON.stringify(subOrgModelAccess.filter((val) => val !== option.split(' - ')[0]))))
                  } else {
                    setSubOrgModelAccess([...subOrgModelAccess, option.split(' - ')[0]])
                  }
                }
              }}
            />
            {subOrgModelAccess.map((modelId) => {
              return (
                <Fragment key={`${modelId}-price`}>
                  <Switch
                    key={`${modelId}-switch`}
                    label={`Set ${props.modelInformation[modelId]?.public?.metadata?.name} Pricing`}
                    checked={subOrgModelPricing?.[modelId]?.isSetting}
                    value={subOrgModelPricing?.[modelId]?.isSetting}
                    onChange={(e) => {
                      if (canChangeSubOrg(props.orgDetails)) {
                        if (!subOrgModelPricing?.[modelId]) {
                          subOrgModelPricing[modelId] = {isSetting: true}
                        } else if (!subOrgModelPricing?.[modelId]?.isSetting) {
                          subOrgModelPricing[modelId].isSetting = true
                        } else {
                          subOrgModelPricing[modelId].isSetting = false
                        }
                        setSubOrgModelPricing(JSON.parse(JSON.stringify(subOrgModelPricing)))
                      }
                    }}
                    theme={props.theme}
                    id={`${modelId}-switch`}
                  />
                  {subOrgModelPricing?.[modelId]?.isSetting &&
                  <>
                    <Input
                      label='Price Per Page'
                      placeholder='Enter the price per page...'
                      theme={props.theme}
                      type={1}
                      value={`${subOrgModelPricing?.[modelId]?.perPage || ''}`}
                      onChange={(e) => {
                        if (canChangeSubOrg(props.orgDetails)) {
                          subOrgModelPricing[modelId].perPage = e.target.value
                          setSubOrgModelPricing(JSON.parse(JSON.stringify(subOrgModelPricing)))
                        }
                      }}
                      min={0}
                      className={(!subOrgModelPricing[modelId].perPage || parseFloat(subOrgModelPricing[modelId].perPage) > 0) ? '': 'input-error'}
                      style={{marginBottom: '0px', boxSizing: 'border-box', paddingLeft: '16px'}}
                      key={`${modelId}-price-page`}
                    />
                    <Input
                      label='Price Per Question'
                      placeholder='Enter the price per question...'
                      theme={props.theme}
                      type={1}
                      value={`${subOrgModelPricing?.[modelId]?.perQuestion || ''}`}
                      onChange={(e) => {
                        if (canChangeSubOrg(props.orgDetails)) {
                          subOrgModelPricing[modelId].perQuestion = e.target.value
                          setSubOrgModelPricing(JSON.parse(JSON.stringify(subOrgModelPricing)))
                        }
                      }}
                      min={0}
                      className={(!subOrgModelPricing[modelId].perQuestion || parseFloat(subOrgModelPricing[modelId].perQuestion) > 0) ? '': 'input-error'}
                      style={{marginBottom: '0px', boxSizing: 'border-box', paddingLeft: '16px'}}
                      key={`${modelId}-price-question`}
                    />
                    <Input
                      label='Price Per Request'
                      placeholder='Enter the price per request...'
                      theme={props.theme}
                      type={1}
                      value={`${subOrgModelPricing?.[modelId]?.perRequest || ''}`}
                      onChange={(e) => {
                        if (canChangeSubOrg(props.orgDetails)) {
                          subOrgModelPricing[modelId].perRequest = e.target.value
                          setSubOrgModelPricing(JSON.parse(JSON.stringify(subOrgModelPricing)))
                        }
                      }}
                      min={0}
                      className={(!subOrgModelPricing[modelId].perRequest || parseFloat(subOrgModelPricing[modelId].perRequest) > 0) ? '': 'input-error'}
                      style={{marginBottom: '0px', boxSizing: 'border-box', paddingLeft: '16px'}}
                      key={`${modelId}-price-request`}
                    />
                  </>
                  }
                </Fragment>
              )
            })}
            {hasModelAccessOrPricingChanged(subOrgModelAccess, subOrgModelPricing) && canChangeSubOrg(props.orgDetails) &&
              <Button
                style={{marginBottom: '0px'}}
                text={'Save Changes'}
                theme={props.theme}
                onClick={async () => {
                  props.storePayload({
                    userMessage: 'Updating model access and pricing...',
                    notificationType: 2,
                    notificationIcon: 'check',
                  })
                  const modelAdd = subOrgModelAccess.filter((modelId) => !props.subOrganizationDetails?.models?.[modelId])
                  const modelDel = Object.keys(props.subOrganizationDetails?.models || {}).filter((modelId) => !subOrgModelAccess.includes(modelId))
                  const modelPricing = Object.assign({}, ...Object.keys(subOrgModelPricing)
                      .filter((modelId) => subOrgModelPricing[modelId]['isSetting'] && subOrgModelAccess.includes(modelId))
                      .map((modelId) => {
                        const interObj = {}
                        interObj['perPage'] = parseFloat(subOrgModelPricing[modelId]['perPage']) || null
                        interObj['perQuestion'] = parseFloat(subOrgModelPricing[modelId]['perQuestion']) || null
                        interObj['perRequest'] = parseFloat(subOrgModelPricing[modelId]['perRequest']) || null
                        const returnObj = {}
                        returnObj[modelId] = interObj
                        return returnObj
                      }))
                  await props.setSubOrgModelAccessPricing(props.orgId, props.authKey, props.user.uid,
                      subOrgId, modelAdd, modelDel, modelPricing)
                  props.getSubOrganizationDetails(props.orgId, props.authKey, props.user.uid, subOrgId, true)
                }}
              />
            }
          </Card>
        </div>
      }
      {props.subOrganizationDetails &&
        <div className='sub-org-row'>
          <div className='sub-org-row-title'>
            Limits
            {!canChangeSubOrg(props.orgDetails) && <span className='view-only'>{' View Only'}</span>}
            {haveLimitsChanged(subOrgLimits) && <>
              <span className='unsaved-text'>{'   Unsaved Changes   '}</span>
              <span
                onClick={() => initializeLimitState(true)}
                className='reset-text'
              >Reset Changes</span>
            </>}
          </div>
          <Card
            theme={props.theme}
            className='sub-org-row-body sub-org-row-spacing'
          >
            <span>Credit Limit</span>
            <Input
              label='Organization Credit Limit ($ / period)'
              placeholder='Enter the credit limit amount...'
              theme={props.theme}
              type={1}
              value={`${subOrgLimits?.creditLimit?.creditLimit || ''}`}
              onChange={(e) => {
                if (canChangeSubOrg(props.orgDetails)) {
                  const newSubOrgLimits = JSON.parse(JSON.stringify(subOrgLimits))
                  if (!newSubOrgLimits.creditLimit) {
                    newSubOrgLimits.creditLimit = {}
                  }
                  newSubOrgLimits.creditLimit.creditLimit = e.target.value
                  setSubOrgLimits(newSubOrgLimits)
                }
              }}
              min={0}
              className={(!subOrgLimits?.creditLimit?.creditLimit || parseFloat(subOrgLimits?.creditLimit?.creditLimit) > 0) ? '': 'input-error'}
              style={{marginBottom: '0px'}}
            />
            <Select
              label='Organization Credit Limit Period (period in which the credit limit is assigned)'
              theme={props.theme}
              isSelectedYellow={true}
              options={periodOptions}
              value={subOrgLimits?.creditLimit?.period}
              onClickOption={(option) => {
                if (canChangeSubOrg(props.orgDetails)) {
                  const newSubOrgLimits = JSON.parse(JSON.stringify(subOrgLimits))
                  if (!newSubOrgLimits.creditLimit) {
                    newSubOrgLimits.creditLimit = {}
                  }
                  newSubOrgLimits.creditLimit.period = (option === 'reset') ? null : option
                  setSubOrgLimits(newSubOrgLimits)
                }
              }}
            />
            <br/>
            <span>Page Limit</span>
            <Input
              label='Organization Page Limit (# pages / period)'
              placeholder='Enter the Page limit amount...'
              theme={props.theme}
              type={1}
              value={`${subOrgLimits?.pageLimit?.pageLimit || ''}`}
              onChange={(e) => {
                if (canChangeSubOrg(props.orgDetails)) {
                  const newSubOrgLimits = JSON.parse(JSON.stringify(subOrgLimits))
                  if (!newSubOrgLimits.pageLimit) {
                    newSubOrgLimits.pageLimit = {}
                  }
                  newSubOrgLimits.pageLimit.pageLimit = e.target.value
                  setSubOrgLimits(newSubOrgLimits)
                }
              }}
              min={0}
              className={(!subOrgLimits?.pageLimit?.pageLimit || parseFloat(subOrgLimits?.pageLimit?.pageLimit) > 0) ? '': 'input-error'}
              style={{marginBottom: '0px'}}
            />
            <Select
              value={subOrgLimits?.pageLimit?.period}
              label='Organization Page Limit Period (period in which the page limit is assigned)'
              theme={props.theme}
              isSelectedYellow={true}
              options={periodOptions}
              onClickOption={(option) => {
                if (canChangeSubOrg(props.orgDetails)) {
                  const newSubOrgLimits = JSON.parse(JSON.stringify(subOrgLimits))
                  if (!newSubOrgLimits.pageLimit) {
                    newSubOrgLimits.pageLimit = {}
                  }
                  newSubOrgLimits.pageLimit.period = (option === 'reset') ? null : option
                  setSubOrgLimits(newSubOrgLimits)
                }
              }}
            />
            {(subOrgModelAccess.length > 0) && <>
              <br />
              <span>Model Limits</span>
            </>}
            {subOrgModelAccess.map((modelId) => {
              return (
                <Fragment key={`${modelId}-limits`}>
                  <br/>
                  <span>{modelId} Limits</span>
                  <Input
                    label={`${modelId} Credit Limit ($ credits / period)`}
                    placeholder='Enter the credit limit amount...'
                    theme={props.theme}
                    type={1}
                    value={`${subOrgLimits?.modelLimit?.[modelId]?.creditLimit || ''}`}
                    onChange={(e) => {
                      if (canChangeSubOrg(props.orgDetails)) {
                        const newSubOrgLimits = JSON.parse(JSON.stringify(subOrgLimits))
                        if (!newSubOrgLimits.modelLimit) {
                          newSubOrgLimits.modelLimit = {}
                        }
                        if (!newSubOrgLimits.modelLimit[modelId]) {
                          newSubOrgLimits.modelLimit[modelId] = {}
                        }
                        newSubOrgLimits.modelLimit[modelId].creditLimit = e.target.value
                        setSubOrgLimits(newSubOrgLimits)
                      }
                    }}
                    min={0}
                    className={(!subOrgLimits?.modelLimit?.[modelId]?.creditLimit || parseFloat(subOrgLimits?.modelLimit?.[modelId]?.creditLimit) > 0) ? '': 'input-error'}
                    style={{marginBottom: '0px'}}
                  />
                  <Input
                    label={`${modelId} Page Limit (# pages / period)`}
                    placeholder='Enter the Page limit amount...'
                    theme={props.theme}
                    type={1}
                    value={`${subOrgLimits?.modelLimit?.[modelId]?.pageLimit || ''}`}
                    onChange={(e) => {
                      if (canChangeSubOrg(props.orgDetails)) {
                        const newSubOrgLimits = JSON.parse(JSON.stringify(subOrgLimits))
                        if (!newSubOrgLimits.modelLimit) {
                          newSubOrgLimits.modelLimit = {}
                        }
                        if (!newSubOrgLimits.modelLimit[modelId]) {
                          newSubOrgLimits.modelLimit[modelId] = {}
                        }
                        newSubOrgLimits.modelLimit[modelId].pageLimit = e.target.value
                        setSubOrgLimits(newSubOrgLimits)
                      }
                    }}
                    min={0}
                    className={(!subOrgLimits?.modelLimit?.[modelId]?.pageLimit || parseFloat(subOrgLimits?.modelLimit?.[modelId]?.pageLimit) > 0) ? '': 'input-error'}
                    style={{marginBottom: '0px'}}
                  />
                  <Input
                    label={`${modelId} Prompt Limit (# prompts / period)`}
                    placeholder='Enter the prompt limit amount...'
                    theme={props.theme}
                    type={1}
                    value={`${subOrgLimits?.modelLimit?.[modelId]?.promptLimit || ''}`}
                    onChange={(e) => {
                      if (canChangeSubOrg(props.orgDetails)) {
                        const newSubOrgLimits = JSON.parse(JSON.stringify(subOrgLimits))
                        if (!newSubOrgLimits.modelLimit) {
                          newSubOrgLimits.modelLimit = {}
                        }
                        if (!newSubOrgLimits.modelLimit[modelId]) {
                          newSubOrgLimits.modelLimit[modelId] = {}
                        }
                        newSubOrgLimits.modelLimit[modelId].promptLimit = e.target.value
                        setSubOrgLimits(newSubOrgLimits)
                      }
                    }}
                    min={0}
                    className={(!subOrgLimits?.modelLimit?.[modelId]?.promptLimit || parseFloat(subOrgLimits?.modelLimit?.[modelId]?.promptLimit) > 0) ? '': 'input-error'}
                    style={{marginBottom: '0px'}}
                  />
                  <Select
                    value={subOrgLimits?.modelLimit?.[modelId]?.period}
                    label={`${modelId} Page Limit Period (period in which the page limit is assigned)`}
                    theme={props.theme}
                    isSelectedYellow={true}
                    options={periodOptions}
                    onClickOption={(option) => {
                      if (canChangeSubOrg(props.orgDetails)) {
                        const newSubOrgLimits = JSON.parse(JSON.stringify(subOrgLimits))
                        if (!newSubOrgLimits.modelLimit) {
                          newSubOrgLimits.modelLimit = {}
                        }
                        if (!newSubOrgLimits.modelLimit[modelId]) {
                          newSubOrgLimits.modelLimit[modelId] = {}
                        }
                        newSubOrgLimits.modelLimit[modelId].period = (option === 'reset') ? null : option
                        setSubOrgLimits(newSubOrgLimits)
                      }
                    }}
                  />
                </Fragment>
              )
            })}
            {haveLimitsChanged(subOrgLimits) && canChangeSubOrg(props.orgDetails) && <Button
              style={{marginBottom: '0px'}}
              text={'Save Changes'}
              theme={props.theme}
              onClick={async () => {
                props.storePayload({
                  userMessage: 'Updating limits...',
                  notificationType: 1,
                  notificationIcon: 'check',
                })
                await props.setSubOrgLimits(props.orgId, props.authKey, props.user.uid,
                    subOrgId, subOrgLimits, subOrgModelAccess)
                props.getSubOrganizationDetails(props.orgId, props.authKey, props.user.uid, subOrgId, true)
              }}
            />}
          </Card>
        </div>
      }
      {props.subOrganizationDetails &&
        <div className='sub-org-row'>
          <div className='sub-org-row-title'>
            Sub Org Control
            {!canChangeSubOrg(props.orgDetails) && <span className='view-only'>{' View Only'}</span>}
            {subOrgCanCreateSubOrg !== props.subOrganizationDetails?.orgPermissions?.canCreateSubOrg && <>
              <span className='unsaved-text'>{'   Unsaved Changes   '}</span>
              <span
                onClick={() => initializeCanCreateSubOrg(true)}
                className='reset-text'
              >Reset Changes</span>
            </>}
          </div>
          <Card
            theme={props.theme}
            className={`sub-org-row-body sub-org-row-spacing ${!canChangeSubOrg(props.orgDetails) ? 'pointer-events-none': ''}`}
          >
            Granting this access will allow this organization to create more sub organizations and control the access/pricing/.etc of those organizations
            <Switch
              key={'permission-switch'}
              label={`Can Create Sub Orgs`}
              checked={subOrgCanCreateSubOrg}
              onChange={(e) => {
                if (canChangeSubOrg(props.orgDetails)) {
                  setSubOrgCanCreateSubOrg(e.target.checked)
                }
              }}
              theme={props.theme}
              id={`permission-switch`}
            />
            {subOrgCanCreateSubOrg !== props.subOrganizationDetails?.orgPermissions?.canCreateSubOrg &&
              canChangeSubOrg(props.orgDetails) &&
              <Button
                style={{marginBottom: '0px'}}
                text={'Save Changes'}
                theme={props.theme}
                onClick={async () => {
                  props.storePayload({
                    userMessage: 'Updating permissions...',
                    notificationType: 1,
                    notificationIcon: 'check',
                  })
                  // API CALL TO UPDATE
                  await props.updateSubOrgCreatePermission(props.orgId, props.authKey, props.user.uid,
                      subOrgId, subOrgCanCreateSubOrg)
                  props.getSubOrganizationDetails(props.orgId, props.authKey, props.user.uid, subOrgId, true)
                }}
              />
            }
          </Card>
        </div>
      }{props.subOrganizationDetails &&
        props.subOrgAuthKey &&
        <div className='sub-org-row'>
          <div className='sub-org-row-title'>
            Reset Sub Organization Auth Key
          </div>
          <Card
            theme={props.theme}
            className='sub-org-row-body sub-org-row-spacing reset-auth-key-body'
          >
            Resetting this Sub Organizations Auth Key will immediately affect the processes of this organization
            <Switch
              key={'auth-key-switch'}
              label={`I confirm that I want to reset ${props.subOrganizationDetails.orgName}'s Auth Key`}
              checked={confirmResetAuthKey}
              onChange={(e) => {
                setConfirmResetAuthKey(e.target.checked)
              }}
              theme={props.theme}
              type='checkbox'
              id={`auth-key-switch`}
            />
            {confirmResetAuthKey &&
              <Button
                style={{marginBottom: '0px'}}
                text={'Save Changes'}
                theme={props.theme}
                onClick={async () => {
                  if (confirm(`Are you sure that you want to reset ${props.subOrganizationDetails.orgName}'s Auth Key?`)) {
                    props.storePayload({
                      userMessage: 'Resetting Auth Key...',
                      notificationType: 1,
                      notificationIcon: 'check',
                    })
                    await props.resetSubOrganizationAuthKey(props.orgId, props.authKey, props.user.uid,
                        subOrgId)
                    setConfirmResetAuthKey(false)
                    setIsSubOrgAuthKeyShowing(false)
                  } else {
                    setConfirmResetAuthKey(false)
                  }
                }}
              />
            }
          </Card>
        </div>
      }
      <div className='sub-org-bottom-padding'>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  database: state.firebaseReducer.database,
  usersObj: state.userReducer.usersObj,
  users: state.userReducer.users,
  userData: state.userReducer.userData,
  user: state.userReducer.user,
  orgDetails: state.userReducer.orgDetails,
  theme: state.userReducer.theme,
  orgId: state.userReducer.orgId,
  subOrganizationMetrics: state.userReducer.subOrganizationMetrics,
  subOrganizationDetails: state.userReducer.subOrganizationDetails,
  modelAccess: state.userReducer.modelAccess,
  modelInformation: state.userReducer.modelInformation,
  authKey: state.userReducer.authKey,
  vkgDomain: state.userReducer.vkgDomain,
  subOrgAuthKey: state.userReducer.subOrgAuthKey,
})

export default connect(mapStateToProps, {
  getUsers,
  storePayload,
  // getBilling,
  getSubOrganizationDetails,
  setSubOrgModelAccessPricing,
  setSubOrgLimits,
  getSubOrganizationAuthKey,
  resetSubOrganizationAuthKey,
  getSubOrgBillingHistory,
  updateSubOrgCreatePermission,
})(SubOrgView)
