import React, {useState, useEffect} from 'react'
import {Table, TableEntry} from '@lazarusai/forms-ui-components'
import {connect} from 'react-redux'
import {storePayload} from '../actions/storePayload.js'
import {getLogData} from '../actions/getLogData.js'
import {getLogs} from '../actions/getLogs.js'
import {logAnalyticsEvent} from '../actions/logAnalyticsEvent'
import codeDLIcon from '../images/evaIcons/code-download-outline.svg'
import '../styles/Logs.css'
import '../styles/Table.css'

function Logs(props) {
  // const initialLogCount = 50
  // const [renderedLogCount, _setRenderedLogCount] = useState(initialLogCount)
  const [entries, setEntries] = useState([])
  // const renderedLogCountRef = useRef(renderedLogCount)
  const {scrollRef, logs} = props

  // const setRenderedLogCount = (data) => {
  //   renderedLogCountRef.current = data;
  //   _setRenderedLogCount(data);
  // }

  function onClickGetData(log) {
    if (!props.isGettingLogData) {
      const logDataToDl = props.logData[log.id]
      if (!logDataToDl) {
        props.storePayload({isGettingLogData: true})
        props.getLogData(props.orgId, log)
      }
    }
  }

  useEffect(() => {
    if (props.newLogId) {
      const dlAnchorElem = document.getElementById(props.newLogId)
      if (dlAnchorElem) {
        const logData = props.logData[props.newLogId]
        const dataStr =
          'data:text/json;charset=utf-8,' +
          encodeURIComponent(JSON.stringify(logData))
        dlAnchorElem.setAttribute('href', dataStr)
        dlAnchorElem.setAttribute('download', `${props.newLogId}.json`)
        dlAnchorElem.click()
        props.logAnalyticsEvent('log_get_data', {
          'user_id': props.user.uid,
          'hipaa_mode_on': props.orgDetails && props.orgDetails['hipaaMode'] ? props.orgDetails['hipaaMode'] : false,
          'organization': props.orgId,
          'log_id': logData['id'],
          'api_name': logData['apiName'],
          'log_event': logData['event'],
          'page': window.location.pathname.substring(1),
          'time': Date.now(),
          'user': window.navigator.userAgent,
          'event_type': 'click',
        })
      }
    }
  }, [props.newLogId])

  const canGetLogData =
    !props.usersObj?.[props.user.uid]?.permissions ||
    props.usersObj?.[props.user.uid]?.permissions?.viewLogData


  useEffect(() => {
    if (logs && logs.length) {
      const newEntries = logs.map((log, i) => {
        return (
          <TableEntry
            key={log.id}
            rowId={`log-${log.id}`}
            content={[
              log['date_time_string'],
              log['model_name'] || log['model_id'] || 'N/A',
              [log['status'], log['status']],
              <div
                className={canGetLogData ? 'cursor-pointer' : 'disabled cursor-not-allowed'}
                key={log.id}
                onClick={(e) => {
                  e.stopPropagation()
                  if (canGetLogData) {
                    onClickGetData(log)
                  }
                }}
              >
                <a
                  id={log.id}
                  className={
                    canGetLogData ?
                      'flex align-center' :
                      'flex align-center cursor-not-allowed'
                  }
                  href={null}
                  target='_blank'
                  rel='noreferrer'
                  onClick={(e) => {
                    if (!canGetLogData) {
                      e.preventDefault()
                    }
                  }}
                >
                  <img className='eva-icon' src={codeDLIcon} alt=''></img>
                  Get Data
                </a>
              </div>,
            ]}
          />
        )
      })
      setEntries(newEntries)
    }
  }, [logs])

  useEffect(() => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.onscroll = () => {
        const scrollTop = scrollRef.current.scrollTop
        const buffer = 10
        const isScrolledToBottom =
          scrollRef.current.scrollHeight - scrollTop <
          scrollRef.current.offsetHeight + buffer
        if (isScrolledToBottom) {
          props.getLogs(props.orgId)
        }
      }
    }
  }, [scrollRef, logs])

  if (logs && logs.length > 0) {
    return (
      <Table
        title='Logs'
        total={
          /* Total requests doesn't include the response,
              so I'm multiplying it by 2
          */
          props.metrics && props.metrics.totalMetrics ?
            props.metrics.totalMetrics.totalRequests * 2 :
            0
        }
        columnTitles={['Time', 'API', 'Event', 'Data']}
        entries={entries}
        theme={props.theme}
        showTotal={true}
        showCount={true}
        isLogs={true}
        scrollRef={scrollRef}
        className='table-container'
      />
    )
  } else {
    return null
  }
}

const mapStateToProps = (state) => ({
  database: state.firebaseReducer.database,
  user: state.userReducer.user,
  usersObj: state.userReducer.usersObj,
  metrics: state.userReducer.metrics,
  orgId: state.userReducer.orgId,
  logData: state.userReducer.logData,
  isGettingLogData: state.userReducer.isGettingLogData,
  newLogId: state.userReducer.newLogId,
  user: state.userReducer.user,
  orgDetails: state.userReducer.orgDetails,
  analytics: state.firebaseReducer.analytics,
  theme: state.userReducer.theme,
})

export default connect(mapStateToProps, {
  getLogData,
  storePayload,
  logAnalyticsEvent,
  getLogs,
})(Logs)
