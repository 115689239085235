import types from '../types'
import Helpers from '../Helpers.js'

export const getVKGs = () => (dispatch, getState) => {
  // const url = process.env.REACT_APP_URL + 'log/metrics'
  const userReducer = getState().userReducer
  const usersObj = userReducer.usersObj
  const uid = userReducer.user.uid
  const licenseKey = userReducer.licenseKey
  const vkgDomain = userReducer.vkgDomain
  const url = vkgDomain + '/api/vkgs'

  const headers = {'userId': uid, 'authKey': userReducer.authKey, 'orgId': userReducer.orgId, 'Authorization': `Bearer ${licenseKey}`}
  if (vkgDomain && (usersObj[uid]['role'] === 'admin' || usersObj[uid]['permissions']['readVKG'] || usersObj?.[uid]?.vkgAccess)) {
    Helpers.fetchGet(url, headers)
        .then((res) => {
          if (res.status === 'FAILURE') {
            dispatch({
              type: types.STORE_PAYLOAD,
              payload: {
                graphObj: null,
                userMessage: `Error getting Graphs: ${res.message}`,
                notificationType: 1,
                notificationIcon: 'warning',
                isNotificationVisible: true,
                isSignedIn: false,
              },
            })
          } else {
            dispatch({
              type: types.STORE_PAYLOAD,
              payload: {
                graphObj: res.vkgs,
              },
            })
          }
        })
  } else {
    // no read access for VKG or no VKG Domain
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        graphObj: null,
      },
    })
  }
}
