import React, {useState, useEffect} from 'react'
import {Table, TableEntry} from '@lazarusai/forms-ui-components'
import {connect} from 'react-redux'
import {storePayload} from '../actions/storePayload.js'
import {getLogData} from '../actions/getLogData.js'
import {logAnalyticsEvent} from '../actions/logAnalyticsEvent'
import '../styles/Logs.css'
import '../styles/Table.css'

function ModelUsage(props) {
  const [entries, setEntries] = useState([])
  const {models} = props

  useEffect(() => {
    if (models?.[props.orgId] && Object.keys(props.models?.[props.orgId]).length) {
      const newEntries = Object.keys(props.models?.[props.orgId]).map((modelId, i) => {
        const model = props.models?.[props.orgId][modelId]
        return (
          <TableEntry
            key={modelId}
            content={[
              modelId,
              model.model,
              model.lastUsed,
              model.successfulRequests,
              model.failedRequests,
              model.totalPages,
              model.totalQuestions,
            ]}
          />
        )
      })
      setEntries(newEntries)
    }
  }, [models])

  if (models?.[props.orgId] && Object.keys(props.models?.[props.orgId]).length > 0) {
    return (
      <Table
        title='Models'
        columnTitles={['ID', 'Name', 'Last Use', 'Successes', 'Failures', 'Total Pages', 'Total Questions']}
        entries={entries}
        theme={props.theme}
        showTotal={true}
        showCount={true}
        className='table-container'
      />
    )
  } else {
    return null
  }
}

const mapStateToProps = (state) => ({
  orgId: state.userReducer.orgId,
  theme: state.userReducer.theme,
})

export default connect(mapStateToProps, {
  getLogData,
  storePayload,
  logAnalyticsEvent,
})(ModelUsage)
