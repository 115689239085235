import types from '../types'
import firebase from 'firebase/compat/app'

export const signOut = (orgId) => (dispatch, getState) => {
  const database = getState().firebaseReducer.database
  const billingRef = database
      .ref(`organizations/${orgId}/billing`)
  const authTokenRef = database
      .ref(`organizations/${orgId}/activeApis/genericFormApi/authToken`)
  const orgDetailsRef = database
      .ref(`organizations/${orgId}/orgDetails`)
  const userRosterRef = database
      .ref(`organizations/${orgId}/userRoster`)
  const orgActionLogRef = database
      .ref(`organizations/${orgId}/orgActionLogs`)
  billingRef.off()
  authTokenRef.off()
  orgDetailsRef.off()
  userRosterRef.off()
  orgActionLogRef.off()

  firebase.auth().signOut()
      .then(() => {
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            isSignedIn: false,
            isLoading: false,
            users: null,
            logs: null,
          },
        })
      })
      .then(() => dispatch({type: 'CLEAR_STORE'}))
      .catch((error) => {
        // console.log('Error on sign out', error)
      })
}

