import types from '../types'
import Helpers from '../Helpers.js'
import endpoints from '.././endpoints.json'

export const setSubOrgModelAccessPricing = (orgId, authKey, userId, subOrgId, modelsAdd, modelsDelete, modelPricing) => async (dispatch) => {
  const url = process.env.REACT_APP_URL + endpoints.subOrgsAccess
  const headers = {
    'ORGID': orgId,
    'AUTHKEY': authKey,
    'USERID': userId,
    'suborgid': subOrgId,
  }

  const [respAdd, respDelete] = await Promise.all(
      [Helpers.fetchPostJson(url, {modelIds: modelsAdd, subOrgId: subOrgId}, headers)
          .then((res) => {
            return res
          })
          .catch((res) => {
            console.log('error res', res)
            return {status: 'FAILURE'}
          }),
      Helpers.fetchDeleteJson(url, headers, {modelIds: modelsDelete, subOrgId: subOrgId})
          .then((res) => {
            return res
          })
          .catch((res) => {
            console.log('error res', res)
            return {status: 'FAILURE'}
          })],
  )
  const urlPrice = process.env.REACT_APP_URL + endpoints.subOrgsBilling
  const body = {
    subOrgId: subOrgId,
    billing: {pricing: modelPricing},
  }
  const respPrice = await Helpers.fetchPostJson(urlPrice, body, headers)
      .then((res) => {
        return res
      })
      .catch((res) => {
        console.log('error res', res)
        return {status: 'FAILURE'}
      })
  const messages = []
  const responses = [respAdd, respDelete, respPrice]
  const allSuccessful = responses.every((resp) => resp.status === 'SUCCESS')
  const actions = ['Add', 'Delete', 'Pricing']
  for (let rIndex = 0; rIndex < responses.length; rIndex++) {
    messages.push(`Model ${actions[rIndex]} ${responses[rIndex]?.status === 'SUCCESS' ? 'Successful': `Failed: ${responses[rIndex]?.error ? Helpers.parseErrorMessages(responses[rIndex]?.error) : responses[rIndex]?.message ? Helpers.parseErrorMessages(responses[rIndex]?.message) : ''}`}`)
  }
  dispatch({
    type: types.STORE_PAYLOAD,
    payload: {
      userMessage: messages.join('\n'),
      notificationIcon: allSuccessful ? 'check': 'warning',
      notificationType: 1,
    },
  })
  return allSuccessful
}
