import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {storePayload} from '../actions/storePayload'
import {Icon, Switch, Input, Button} from '@lazarusai/forms-ui-components'
import SideTab from './SideTab'
import Helpers from '../Helpers.js'

import '../styles/SideTab.css'

function PermissionSideTab({
  isVisible=false,
  onClose=()=>{},
  onSave=()=>{},
  headerText = 'User Permissions',
  showClose = true,
  hasBackground = true,
  email,
  permissionUser,
  id,
  ...props
}) {
  const [collapsedPermissionSections, setCollapsedPermissionSections] = useState([])
  const [changedUserPermissions, setChangedUserPermissions] = useState({})
  const [hasFullVKGAccess, setHasFullVKGAccess] = useState(false)
  const [vkgSearchString, setVKGSearchString] = useState('')
  const [selectedVKGs, setSelectedVKGs] = useState([])
  const [changedRole, setChangedRole] = useState(null)

  useEffect(() => {
    setChangedUserPermissions(permissionUser?.permissions)
    setChangedRole(permissionUser?.role)
    setHasFullVKGAccess(permissionUser?.vkgAccess?.vkgs?.includes('read') ||
      permissionUser?.vkgAccess?.vkgs?.includes('write'),
    )
    if (permissionUser?.vkgAccess) {
      setSelectedVKGs(Object.keys(permissionUser?.vkgAccess)
          .filter((vkgId) => vkgId !== 'vkgs')
          .map((vkgId) => Helpers.decodeVKGId(vkgId)),
      )
    }
  }, [permissionUser])

  function resetTab() {
    setCollapsedPermissionSections([])
    setChangedUserPermissions({})
    setHasFullVKGAccess(false)
    setVKGSearchString('')
    setSelectedVKGs([])
    setChangedRole(null)
  }

  const roles = ['basic', 'intermediate', 'admin']

  const permissionStructure = {
    'Billing and Credits': ['manageBilling', 'useDemoCredits'],
    'Admin': ['setPermissions', 'inviteUser', 'resetAuthKey', 'viewCredentials'],
    'Log Data': ['viewLogs', 'viewAuditLogs', 'viewLogData'],
    'Vector Knowledge Graphs': ['readVKG', 'writeVKG'],
  }

  const permissionsInfo = {
    'manageBilling': {
      'name': 'View/manage billing',
      'role': 'admin',
    },
    'useDemoCredits': {
      'name': 'Use demo credit',
      'role': 'intermediate',
    },
    'setPermissions': {
      'name': 'Set user permissions',
      'role': 'admin',
    },
    'inviteUser': {
      'name': 'Invite new users',
      'role': 'admin',
    },
    'resetAuthKey': {
      'name': 'Reset auth key',
      'role': 'admin',
    },
    'viewCredentials': {
      'name': 'View credentials',
      'role': 'intermediate',
    },
    'viewLogs': {
      'name': 'View basic log data',
      'role': 'basic',
    },
    'viewAuditLogs': {
      'name': 'View audit log data',
      'role': 'admin',
    },
    'viewLogData': {
      'name': 'Download log data',
      'role': 'intermediate',
    },
    'readVKG': {
      'name': 'Read VKGs',
      'role': 'basic',
      'description': 'Get graphs and nodes, search VKG, use RikYChat, and export VKGs.',
    },
    'writeVKG': {
      'name': 'Write VKGs',
      'role': 'admin',
      'description': 'Create VKGs, add, edit, and delete nodes, delete VKGs, compute TSNE, and duplicate VKGs.',
    },
  }

  function onChangeRole(newRole) {
    const newRolePosition = roles.indexOf(newRole)
    const permRoleLevels = roles.filter((role) => roles.indexOf(role) <= newRolePosition)
    const newPerms = JSON.parse(JSON.stringify(changedUserPermissions || {}))
    const interPermissionsInfo = {...permissionsInfo}
    if (!props.vkgDomain) {
      delete interPermissionsInfo['readVKG']
      delete interPermissionsInfo['writeVKG']
    }
    const permKeys = Object.keys(interPermissionsInfo)
    for (let pIndex = 0; pIndex < permKeys.length; pIndex++) {
      if (permRoleLevels.includes(interPermissionsInfo[permKeys[pIndex]].role)) {
        newPerms[permKeys[pIndex]] = true
      } else {
        newPerms[permKeys[pIndex]] = false
      }
    }
    setChangedUserPermissions(newPerms)
    setChangedRole(Helpers.capitalizeFirstLetter(newRole))
  }

  useEffect(() => {
    const interPermissionsInfo = {...permissionsInfo}
    if (!props.vkgDomain) {
      delete interPermissionsInfo['readVKG']
      delete interPermissionsInfo['writeVKG']
    }
    const highestRole = Object.keys(changedUserPermissions || {})
        .filter((permissionKey) => changedUserPermissions[permissionKey])
        .map((permissionKey) => interPermissionsInfo[permissionKey]?.role)
        .sort((aRole, bRole) => roles.indexOf(bRole) - roles.indexOf(aRole))[0] || null
    if (highestRole) {
      const permRoles = [...roles].filter((val, indx) => indx <= roles.indexOf(highestRole))
      const areAllRolePermsSelected = Object.keys(interPermissionsInfo)
          .filter((permissionKey) => permRoles.includes(interPermissionsInfo[permissionKey].role))
          .every((permissionKey) => changedUserPermissions?.[permissionKey])
      setChangedRole(areAllRolePermsSelected ? highestRole : '')
    } else {
      setChangedRole(highestRole)
    }
  }, [changedUserPermissions])

  function getSettingsTabContent(email, permissionState, collapsedPermissionSections, vkgSearchString, selectedVKGs, graphObj, changedRole) {
    const interPermissionStructure = {...permissionStructure}
    if (!props.vkgDomain) {
      delete interPermissionStructure['Vector Knowledge Graphs']
    }
    return (
      <>
        <div
          className='permission-side-section permission-email'
        >
          {email}
        </div>
        <div
          className='permission-side-section permission-role'
        >
          <div className='permission-role-label'>
            Role:
          </div>
          <div className='permission-role-input'>
            <Input
              type={2}
              inputType={'select'}
              placeholder={JSON.stringify(permissionState || {}).length > 3 ? 'Custom': 'Select'}
              iconLeft={<Icon icon={'list-outline'} />}
              options={roles.map((role) => Helpers.capitalizeFirstLetter(role))}
              value={Helpers.capitalizeFirstLetter(changedRole)}
              theme={props.theme}
              onChange={(e) => {
                onChangeRole(e.target.value.toLowerCase())
              }}
            />
          </div>
        </div>
        {/** Role Dropdown here */}
        {Object.keys(interPermissionStructure).map((permissionHeader) => {
          const isCollapsed = collapsedPermissionSections.includes(permissionHeader)
          return (<>
            <div
              className='permission-side-section permission-collapse-row'
            >
              <div
                className='permission-collapse-row-text'
              >
                {permissionHeader}
              </div>
              <div
                className='permission-collapse-row-icon'
                onClick={() => {
                  if (isCollapsed) {
                    setCollapsedPermissionSections(
                        collapsedPermissionSections.filter(
                            (val) => val !== permissionHeader),
                    )
                  } else {
                    setCollapsedPermissionSections([...collapsedPermissionSections, permissionHeader])
                  }
                }}
              >
                <Icon
                  icon={isCollapsed ? 'chevron-down-outline': 'chevron-up-outline'}
                  key={isCollapsed ? `${permissionHeader}-closed`: `${permissionHeader}-open`}
                />
              </div>
            </div>
            <div
              className={`permission-collapse-body ${isCollapsed ? 'permission-collapse-body-close': 'permission-collapse-body-open'}`}
            >
              {permissionHeader === 'Vector Knowledge Graphs' && <>
                <div className='permission-sub-header'>
                  Permissions
                </div>
              </>}
              {interPermissionStructure[permissionHeader].map((permissionKey) => {
                return (<>
                  <div
                    className='permission-collapse-body-row permission-side-section'
                  >
                    <div
                      className='permission-collapse-body-row-text-section'
                    >
                      <div className='permission-collapse-body-row-text-main'>
                        {permissionsInfo?.[permissionKey]?.name}
                      </div>
                      {permissionsInfo?.[permissionKey]?.description && <div className='permission-collapse-body-row-text-secondary'>
                        {permissionsInfo?.[permissionKey]?.description}
                      </div>}
                    </div>
                    <div
                      className='permission-collapse-body-row-switch'
                    >
                      <Switch
                        theme={props.theme}
                        onChange={(e) => {
                          const changedPermissions = {[permissionKey]: e.target.checked}
                          if (permissionKey === 'writeVKG' && e.target.checked) {
                            changedPermissions['readVKG'] = true
                          }
                          if (permissionKey === 'readVKG' && !e.target.checked) {
                            changedPermissions['writeVKG'] = false
                          }
                          setChangedUserPermissions({
                            ...permissionState,
                            ...changedPermissions,
                          })
                        }}
                        value={permissionState?.[permissionKey]}
                        checked={permissionState?.[permissionKey]}
                      />
                    </div>
                  </div>
                </>)
              })}
            </div>
          </>)
        })}
        {/* VKG SBAC */}
        {(
          Object.keys(interPermissionStructure).includes('Vector Knowledge Graphs') &&
          !collapsedPermissionSections.includes('Vector Knowledge Graphs')
        ) &&
        <>
          <div className='permission-sub-header'>
            Access
          </div>
          <div
            className='permission-collapse-body-row permission-side-section'
          >
            <div
              className={`permission-collapse-body-row-text-section ${(['read', 'write'].every((perm) => props.userData?.vkgAccess?.vkgs?.includes(perm)))}`}
            >
              <div className='permission-collapse-body-row-text-main'>
              Full VKG Server Access
              </div>
              <div className='permission-collapse-body-row-text-secondary'>
              Access to all VKGs within an organization instance.
              </div>
            </div>
            <div
              className='permission-collapse-body-row-switch'
              onClick={() => {
                setHasFullVKGAccess(true)
              }}
            >
              <Icon
                key={hasFullVKGAccess ? 'radio-button-on-full': 'radio-button-off-full'}
                icon={hasFullVKGAccess ? 'radio-button-on': 'radio-button-off'}
                className={hasFullVKGAccess ? 'permission-active-icon': ''}
              />
            </div>
          </div>
          <div
            className='permission-collapse-body-row permission-side-section'
          >
            <div
              className='permission-collapse-body-row-text-section'
            >
              <div className='permission-collapse-body-row-text-main'>
              Graph-Specific Access Only
              </div>
              <div className='permission-collapse-body-row-text-secondary'>
              Access to only the VKGs specified
              </div>
            </div>
            <div
              className='permission-collapse-body-row-switch'
              onClick={() => {
                setHasFullVKGAccess(false)
              }}
            >
              <Icon
                key={hasFullVKGAccess ? 'radio-button-on': 'radio-button-off'}
                icon={!hasFullVKGAccess ? 'radio-button-on': 'radio-button-off'}
                className={!hasFullVKGAccess ? 'permission-active-icon': ''}
              />
            </div>
          </div>
          {/* VKG Select */}
          {!hasFullVKGAccess && <>
            <div className='permission-side-section permission-select-vkgs-header'>
              Select VKGs
            </div>
            <Input
              theme={props.theme}
              value={vkgSearchString}
              onChange={(e) => {
                setVKGSearchString(e.target.value)
              }}
              iconLeft={<Icon icon={'search-outline'} />}
              placeholder={'Search'}
              className={'permission-side-section'}
            />
            <div
              className='permission-select-vkgs-info permission-side-section'
            >
              <div
                className='permission-select-vkgs-info-count'
              >
                {`${selectedVKGs.length} VKGs selected`}
              </div>
              <div
                className='permission-select-vkgs-info-options'
              >
                <span
                  className='option-text'
                  onClick={() => {
                    setSelectedVKGs(Object.keys(graphObj))
                  }}
                >{`Select All  `}</span>
                <span className='dot-icon'>&bull;</span>
                <span
                  className='option-text'
                  onClick={() => {
                    setSelectedVKGs([])
                  }}
                >{`  Clear`}</span>
              </div>
            </div>
            <div
              className='permission-vkg-lists show-scrollbar'
            >
              {Object.keys(graphObj || {})
                  .sort()
                  .sort((aId, bId) => {
                    const aValueInter = aId.toLowerCase().indexOf(vkgSearchString.toLowerCase())
                    const bValueInter = bId.toLowerCase().indexOf(vkgSearchString.toLowerCase())
                    const aValue = aValueInter > -1 ? aValueInter : 1000
                    const bValue = bValueInter > -1 ? bValueInter : 1000
                    return aValue - bValue
                  })
                  .map(
                      (vkgId) => {
                        return (
                          <div
                            key={`vkg-select-${vkgId}`}
                            className='permission-side-section permission-vkg-select-row'
                          >
                            <div
                              className='permission-vkg-select-check'
                            >
                              <Switch
                                type='checkbox'
                                checked={selectedVKGs.includes(vkgId)}
                                onChange={(e) => {
                                  if (selectedVKGs.includes(vkgId)) {
                                    setSelectedVKGs([...selectedVKGs.filter((graphId) => graphId !== vkgId)])
                                  } else {
                                    setSelectedVKGs([...selectedVKGs, vkgId])
                                  }
                                }}
                                theme={props.theme}
                              />
                            </div>
                            <div
                              className='permission-vkg-select-text'
                            >
                              <div
                                className='permission-vkg-select-name'
                              >
                                {vkgId}
                              </div>
                              <div
                                className='permission-vkg-select-nodes'
                              >
                                {`${graphObj[vkgId]?.nodes} nodes`}
                              </div>
                            </div>
                          </div>
                        )
                      })
              }
            </div>
          </>
          }
        </>}
        <div
          className='permission-side-section permission-action-row'
        >
          <Button
            theme={props.theme}
            type={10}
            text={'Discard'}
            onClick={() => {
              onClose()
              resetTab()
            }}
            width={'fit-content'}
          />
          <Button
            theme={props.theme}
            type={8}
            text={'Save Changes'}
            onClick={() => {
              onSave(changedUserPermissions, id, changedRole?.length > 0 ? changedRole : 'custom', generateVKGAccess())
            }}
            width={'fit-content'}
          />
        </div>
      </>
    )
  }

  function generateVKGAccess() {
    if (hasFullVKGAccess) {
      if (changedUserPermissions?.writeVKG) {
        return {vkgs: 'read,create,write'}
      } else if (changedUserPermissions?.readVKG) {
        return {vkgs: 'read'}
      } else {
        return {}
      }
    } else {
      const retVKGAccess = {}
      if (changedUserPermissions?.writeVKG) {
        retVKGAccess['vkgs'] = 'create'
      }
      const accessString = `${(changedUserPermissions?.readVKG || changedUserPermissions?.writeVKG) ? 'read': ''}${changedUserPermissions?.writeVKG ? ',write': ''}`
      for (let sIndex = 0; sIndex < selectedVKGs.length; sIndex++) {
        retVKGAccess[selectedVKGs[sIndex]] = accessString
      }
      return retVKGAccess
    }
  }

  return (
    <SideTab
      isVisible={isVisible}
      onClose={() => {
        onClose()
        resetTab()
      }}
      headerText={headerText}
      showClose={showClose}
      hasBackground={hasBackground}
      contents={getSettingsTabContent(email, changedUserPermissions,
          collapsedPermissionSections, vkgSearchString, selectedVKGs,
          props.graphObj, changedRole)}
    />
  )
}

const mapStateToProps = (state, ownProps) => ({
  userMessage: state.userReducer.userMessage,
  user: state.userReducer.user,
  database: state.firebaseReducer.database,
  theme: state.userReducer.theme,
  userData: state.userReducer.userData,
  graphObj: state.userReducer.graphObj,
  vkgDomain: state.userReducer.vkgDomain,
})

export default connect(mapStateToProps, {storePayload})(
    PermissionSideTab,
)
