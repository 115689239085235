import React from 'react'
import {connect} from 'react-redux'
import {storePayload} from '../actions/storePayload'
import {Icon} from '@lazarusai/forms-ui-components'

import '../styles/SideTab.css'

function SideTab({
  isVisible=false,
  onClose=()=>{},
  headerText='',
  showClose=true,
  hasBackground=false,
  contents=(<></>),
  ...props
}) {
  return (<>
    {isVisible && <div
      className='permission-side-wrapper'
    >
      <div
        className={`permission-side-main ${hasBackground ? 'permission-side-background': ''}`}
        onClick={onClose}
      >
        <div
          className='permission-side'
          onClick={(e)=>{
            e.stopPropagation()
          }}
        >
          <div
            className='permission-side-section permission-side-header-row'
          >
            <div
              className='permission-side-header-text'
            >
              {headerText}
            </div>
            {showClose && <div
              className='permission-side-header-icon'
              onClick={onClose}
            >
              <Icon
                icon='close-outline'
              />
            </div>}
          </div>
          {contents}
        </div>
      </div>
    </div>}
  </>
  )
}

const mapStateToProps = (state, ownProps) => ({
  userMessage: state.userReducer.userMessage,
  user: state.userReducer.user,
  database: state.firebaseReducer.database,
  theme: state.userReducer.theme,
})

export default connect(mapStateToProps, {storePayload})(
    SideTab,
)
