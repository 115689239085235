import types from '../types'
import Helpers from '../Helpers.js'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import endpoints from '.././endpoints.json'

export const getTimeStats = (orgId, userId) => (dispatch) => {
  // const url = process.env.REACT_APP_URL + 'log/metrics'
  const url = process.env.REACT_APP_URL + endpoints.logMetrics
  const data = {
    org_id: orgId,
    user_id: userId,
  }

  Helpers.fetchPostJson(url, data)
      .then((res) => {
        if (res.data) {
          const lineChartData = res.data.thirty_days_metrics.map((day) => {
            return [new Date(day.date), day.pages]
          })
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              timeStats: res.data,
              lineChartData,
            },
          })
        } else {
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              userMessage: 'Error pulling data',
              notificationIcon: 'warning',
              notificationType: 3,
            },
          })
        }
      })
      .catch((res) => {
        console.log('error res', res)
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            userMessage: 'Error pulling data',
            notificationIcon: 'warning',
            notificationType: 3,
          },
        })
      })
}
